function shipPercentage(order, ship) {
    // Pastikan nilai order dan ship adalah angka positif
    if (typeof order !== 'number' || typeof ship !== 'number' || order < 0 || ship < 0) {
      return 'Input tidak valid. Pastikan order dan ship adalah angka positif.';
    }
  
    // Hindari pembagian oleh nol
    if (order === 0) {
      return 'Tidak ada order yang ditempatkan.';
    }
  
    // Hitung persentase terkirim
    var persentaseTerkirim = ((ship / order) * 100).toFixed(2);
  
    return persentaseTerkirim;
  }
  
 export { shipPercentage }
  
  
  
  
  