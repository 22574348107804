import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "../../";
import { konversiTanggal } from "../../../helper/DatabaseDateConverter"

const ReportTrackingDetailsTable = ({ totalCount, query, page, setPage, list, showing }) => {

  function isInteger(str) {
    return /^-?\d*\.?\d+$/.test(str);
  }

  if (!Array.isArray(list)) {
    return <div>Loading...</div>;
  }
  return (
    <div className="register-button-div h-[700px] sm:h-[460px]  mt-4 ">
      <div className="table-div">
        <form>
          <table className="table-table">
            <thead className="table-thead sticky top-0 border">
              <tr>
                <th
                  rowSpan={2}
                  colSpan={3}
                  className="px-6 border text-center"
                >
                  SO
                </th>
                <th
                  rowSpan={2}
                  colSpan={3}
                  className="px-6 border bg-blue-500  text-white text-center"
                >
                  SHIPMENT
                </th>
                <th
                  colSpan={4}
                  className="px-6 border bg-yellow-300 text-center"
                >
                  SERAH
                </th>
              </tr>
              <tr>
                <th className="px-6 border border-slate-200 bg-yellow-300">
                  CS-GUDANG
                </th>
                <th className="px-6 border border-slate-200 bg-yellow-300">
                  RO-GUDANG
                </th>
                <th className="px-6 border border-slate-200 bg-yellow-300">
                  PENGANTAR
                </th>
                <th className="px-6 border border-slate-200 bg-yellow-300">
                  BALIK
                </th>
              </tr>
              <tr>
                <th className="px-6 border border-slate-200 ">No</th>
                <th className="px-6 border border-slate-200 ">tgl</th>
                <th className="px-6 border border-slate-200 ">
                  tgl create
                </th>
                <th className="px-6 border border-slate-200 bg-blue-500 text-white">
                  No
                </th>
                <th className="px-6 border border-slate-200 bg-blue-500 text-white">
                  tgl
                </th>
                <th className="px-6 border border-slate-200 bg-blue-500 text-white">
                  tgl create
                </th>
                <th className="px-6 border border-slate-200 bg-yellow-300">
                  tgl create
                </th>
                <th className="px-6 border border-slate-200 bg-yellow-300">
                  tgl create
                </th>
                <th className="px-6 border border-slate-200 bg-yellow-300">
                  tgl create
                </th>
                <th className="px-6 border border-slate-200 bg-yellow-300">
                  tgl create
                </th>
              </tr>
            </thead>

            <tbody>
              {list.map((item) => (
                <tr className="hover:bg-slate-200">

                  <td
                    className={
                      isInteger(item.UD21_Key2)
                        ? "text-right px-6 border py-2 text-sm bg-white"
                        : "px-6 py-2 text-sm border bg-white"
                    }
                  >
                    {item.UD21_Key2}
                  </td>
                  <td
                    className={
                      isInteger(konversiTanggal(item.UD21_Date01))
                        ? "text-right px-6 border py-2  text-sm bg-white"
                        : "px-6 py-2  border text-sm bg-white"
                    }
                  >
                    {konversiTanggal(item.UD21_Date01)}
                  </td>
                  <td
                    className={
                      isInteger(konversiTanggal(item.UD21_Date01))
                        ? "text-right px-6 border py-2  text-sm bg-white"
                        : "px-6 py-2  border text-sm bg-white"
                    }
                  >
                    {konversiTanggal(item.UD21_Date01)}
                  </td>
                  <td
                    className={
                      isInteger(item.UD21_Key1)
                        ? "text-right px-6 border py-2 text-sm bg-white"
                        : "px-6 py-2  border text-sm bg-white"
                    }
                  >
                    {item.UD21_Key1}
                  </td>
                  <td
                    className={
                      isInteger(konversiTanggal(item.UD21_Date02))
                        ? "text-right px-6 border py-2 text-sm bg-white"
                        : "px-6 py-2 text-sm border bg-white"
                    }
                  >
                    {konversiTanggal(item.UD21_Date02)}
                  </td>
                  <td
                    className={
                      isInteger(konversiTanggal(item.UD21_Date03))
                        ? "text-right px-6 border py-2 text-sm bg-white"
                        : "px-6 py-2 text-sm border bg-white"
                    }
                  >
                    {konversiTanggal(item.UD21_Date03)}
                  </td>
                  <td
                    className={
                      isInteger(item.UD21_Date04)
                        ? "text-right px-6 border py-2 text-sm bg-white"
                        : "px-6 py-2 text-sm border bg-white"
                    }
                  >
                    {konversiTanggal(item.UD21_Date04)}
                  </td>
                  <td
                    className={
                      isInteger(item.UD21_Date04)
                        ? "text-right px-6 border py-2 text-base bg-white"
                        : "px-6 py-2 text-base border bg-white"
                    }
                  >
                    {konversiTanggal(item.UD21_Date04)}
                  </td>
                  <td
                    className={
                      isInteger(item.UD21_Date05)
                        ? "text-right px-6 border py-2 text-base bg-white"
                        : "px-6 py-2 text-base border bg-white"
                    }
                  >
                    {konversiTanggal(item.UD21_Date05)}
                  </td>
                  <td
                    className={
                      isInteger(konversiTanggal(item.UD21_Date06))
                        ? "text-right px-6 border py-2 text-base bg-white"
                        : "px-6 py-2 text-base border bg-white"
                    }
                  >
                    {konversiTanggal(item.UD21_Date06)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>
      </div>
      <Pagination
        itemsPerPage={showing}
        itemsInPage={list.length}
        totalItems={totalCount}
        totalPages={Math.ceil(totalCount / showing)}
        currentPage={page}
        setCurrentPage={setPage}
      />
    </div>
  );
};

export default ReportTrackingDetailsTable;
