import React, { useState, useEffect, Fragment } from 'react'
import { CreateMenuForm } from '../../component/form/MenuForm'
import { MenuDropDownFilter } from '../../component/filter'
import { ShowingItems, Modal } from '../../component'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SidebarNew2, RegisterTable } from '../../component'
import jwt_decode from "jwt-decode"
import {  Bars3Icon, PlusIcon } from '@heroicons/react/24/outline'
import axios from 'axios'

const DashboardMenu = () => {
  const navigate = useNavigate()
  const [payload, setPayload] = useState({});
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [opened, setOpened] = useState(false)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState({
    item: 5
  })
  const [list, setList] = useState({})
  const [totalCount, setTotalCount] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const header = [ "id", "name", "level", "urut", "parent", "link", "icon"]
  const bodyMapData = ["name", "level", "urut", "parent_id", "link", "icon"]


  const getList = async() => {
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/menu/get/" + page 
    ,{
      params: {
        ...query
      }
    }) ;
    setList(result.data.result.rows);
    setTotalCount(result.data.result.count);
  }

  const getToken = async() => {
    const token = localStorage.getItem("authorization")
    const decode = await jwt_decode(token, {header: process.env.REACT_APP_ACCESS_TOKEN})
    setPayload(decode)
  }

  useEffect(() => {
    getToken()
    getList()
  },[])

  useEffect(() => {
    getList()
  },[page])
  
  return (
    <Fragment>
      <div className = 'flex'>
          <SidebarNew2 opened={opened} setOpened={setOpened}/>
          <section className={`${opened ? "hidden sm:block sm:w-3/4" : "w-full sm:w-11/12"} duration-300 `}>
              <div className='flex flex-col pt-3 px-7 pb-3 bg-slate-200 items-center sm:items-start sticky top-0 z-10'>
                <Bars3Icon
                onClick={() => setOpened(true)} 
                className={`${!opened?"text-slate-500 w-7 h-7 absolute left-7 top-6 sm:hidden":"hidden"}`}
                />
                  <h1 className='text-2xl font-semibold'>Register Menu Page </h1>
                  <div className='flex items-center justify-center mt-1'>
                    <p className='text-xs text-slate-600 font-bold opacity-30 mr-2'>Group :</p>
                    <p className='text-xs text-slate-600 font-semibold opacity-30 pr-3 border-2 border-r-slate-600 border-opacity-30'>{payload.rolegroup}</p>
                    <p className='text-xs text-slate-600 font-bold opacity-30 mx-2'>User :</p>
                    <p className='text-xs text-slate-600 font-semibold opacity-30'>{payload.name} </p>
                  </div>               
              </div>
              <div className='px-9 sm:px-14 py-5 h-4/5 relative' >
                <div className='flex mb-3'>
                  <ShowingItems
                    setSearchParams={setSearchParams}
                    setQuery={setQuery}
                    query={query}
                    getList={getList}
                  />
                  <MenuDropDownFilter
                    setMenuList={setList}
                    setTotalCount={setTotalCount}
                    page={page}
                    setSearchParams={setSearchParams}
                    setQuery={setQuery}
                    query={query}
                  />
                  <button 
                  className='ml-4'
                  onClick={() => setShowModalAdd(!showModalAdd)}>
                    <PlusIcon className='icon-style'/>
                  </button>
                </div>
                <RegisterTable 
                header={header}
                Form={CreateMenuForm}
                bodyMapData={bodyMapData}
                getList={getList}
                viewLink="/register_menu/view/group_menu"
                editLink="/menu/update/"
                deleteLink="/menu/delete/"
                list={list}
                totalCount={totalCount}
                setPage={setPage}
                page={page}
                query={query}
                create={true}
                read={false}
                update={true}
                deleted={true}
                />                
              </div>                   
          </section>
          <Modal isVisible={showModalAdd} onClose={() => setShowModalAdd(false)}>
            <div className="px-6 py-4">
              <CreateMenuForm onClose={() => setShowModalAdd(false)} getList={getList} />
            </div>
          </Modal>         
      </div>
    </Fragment>
    
   
  )
}

export default DashboardMenu