import React, { useEffect } from 'react';
import {useSearchParams} from 'react-router-dom'

const ShowingItems = ({  setQuery, query, getList, setShowing }) => {
    const handleChange = async (e) => {
    const value = e.target.value;
    await setQuery((prev) => ({
      ...prev,
      item: value,
    }));
    console.log("aku di showing", value)

  };

  useEffect(() => {
    getList()
  },[query])

  return (
    <p className="mr-auto text-slate-600">Showing
      <span className="font-semibold border-gray-200 mx-2">
        <select id="item" className='rounded-md' style={{ width: '50px', fontSize: '12px', padding: '5px', borderRadius: '4px' }}
        onChange={handleChange}
        >
          <option value="5"> 5 </option>
          <option value="10"> 10 </option>
          <option value="20"> 20 </option>
          <option value="40"> 40 </option>
        </select>
      </span> results
    </p>
  );
}

export default ShowingItems;
