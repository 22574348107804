import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux"
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { selectedList } from "../../reducers/listSlice";


const CabangFilter = ({ selectedCabang, setSelectedCabang, startDate, endDate }) => {
const [searchParams, setSearchParams] = useSearchParams()
const dispatch = useDispatch()
  return (
    <select
      className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-yellow-400 focus:border-yellow-400 block w-auto ml-3 p-2.5
    disabled:bg-gray-200 disabled:border-none"
    value= {selectedCabang}
    onChange={(e) => {dispatch(selectedList({cabang: e.target.value}));setSelectedCabang(e.target.value); setSearchParams({start: startDate, end:endDate, cabang: e.target.value})}}
    >
      <option value="0" disabled select>
        Pilih Cabang
      </option>
      <option value="MDN">
        Medan
      </option>
      <option value="TGR">
        Tanggerang
      </option>
      <option value="SBY">
        Surabaya
      </option>
      <option value="MKS">
        Makassar
      </option>
      {/* <option>

      </option> */}
    </select>
  );
};

export default CabangFilter;
