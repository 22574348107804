import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { successAlert, errorAlertWithMessage } from "../../../helper/alert";
import axios from "axios";
import * as Yup from "yup";

const CreateForm = ({ onClose, getList, type, id }) => {
  const user = useSelector((state) => state.user);
  const list = useSelector((state) => state.list);
  const [menuList, setMenuList] = useState([]);

  const getMenuRoleSecurityList = async () => {
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/role_user_menu_security/get_one/" + id
    );

    formik.setValues({
      menu_id: result.data.result.menu_id,
      create: result.data.result.create,
      read: result.data.result.read,
      update: result.data.result.update,
      delete: result.data.result.delete,
    });
  };

  const getMenuList = async () => {
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/menu/get_menu_child"
    );
    setMenuList(result.data);
    console.log("cek ini plis", result.data)
  };

  useEffect(() => {
    getMenuList();

    if (type === "update") {
      getMenuRoleSecurityList();
    }
  }, []);

  // ========================================================================

  const userId = user.userInfo.id;
  const formik = useFormik({
    initialValues: {
      menu_id: "",
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    onSubmit: async (values) => {
      const data = {};
      data.menu_id = formik.values.menu_id;
      data.create = formik.values.create;
      data.read = formik.values.read;
      data.update = formik.values.update;
      data.deleted = formik.values.delete;
      try {
        if (type === "create") {
          const result = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/role_user_menu_security/register_new_menu/` +
              userId + "/" + list.list.listId,
            data
          );
          await successAlert(result.data.message);
          onClose();
          getList();
        } else if (type === "update") {
          const result = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/role_user_menu_security/update/${id}/${user.userInfo.id}`,
            data
          );
          await successAlert(result.data.message);
          onClose();
          getList();
        }
      } catch (error) {
        console.log("register error", error);
        await errorAlertWithMessage(error?.response?.data?.message);
      }
    },
    validationSchema: Yup.object({
      // name: Yup.string().required('Required'),
      // level: Yup.string().email('Invalid email format').required("Required"),
    }),
  });
  return (
    <div className="flex flex-col">
      <p className="text-left text-lg font-semibold border-b pb-2 mb-2">
        Register New Menu
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid gap-4 sm:grid-cols-4 sm:gap-x-6 sm:gap-y-2">
          <div className="sm:col-span-4">
            <label htmlFor="name" className="input-label">
              Menu
            </label>
            <select
              className="input-field"
              id="menu_id"
              name="menu_id"
              {...formik.getFieldProps("menu_id")}
              value={formik.values.menu_id} // Make sure you set the selected value
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.setFieldValue("menu_id", selectedValue); // Set the selected value
              }}
            >
              <option value="" disabled selected>
                Select menu
              </option>
              {menuList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center justify-around">
            <input
              type="checkbox"
              id="create"
              name="create"
              checked={formik.values.create === true}
              onChange={formik.handleChange}
            />
            <label for="create">Create</label>
          </div>
          <div className="flex items-center justify-around">
            <input
              type="checkbox"
              id="read"
              name="read"
              checked={formik.values.read === true}
              onChange={formik.handleChange}
            />
            <label for="read">Read</label>
          </div>
          <div className="flex items-center justify-around">
            <input
              type="checkbox"
              id="update"
              name="update"
              checked={formik.values.update === true}
              onChange={formik.handleChange}
            />
            <label for="update">Update</label>
          </div>
          <div className="flex items-center justify-around">
            <input
              type="checkbox"
              id="delete"
              name="delete"
              checked={formik.values.delete === true}
              onChange={formik.handleChange}
            />
            <label for="delete">Delete</label>
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 text-white rounded-md mt-6 py-2 font-bold text-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateForm;
