import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    list:{
        listId:"",
        listName:""
    }
}

export const menuSlice = createSlice({
    name: "list",
    initialState,
    reducers: {
        selectedList: (state, action) => {    
            state.list= {
                listId: action.payload.id,
                listName: action.payload.name,
                universal: action.payload.general,
                cabang: action.payload.cabang
            }
        },
        clearListState: (state) => {
            return initialState;
        }
    }
})

export const { selectedList, clearListState } = menuSlice.actions;
export default menuSlice.reducer;