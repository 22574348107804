import React, { Fragment, useState, useEffect } from "react";
import { SidebarNew2 } from "../../component";
import { MenuButtonsTable } from "../../component/table";
import { MenuButtonForm } from "../../component/form/MenuButtonForm";
import { MenuDropDownFilter } from "../../component/filter";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ShowingItems, Modal } from "../../component";
import { Bars3Icon, PlusIcon } from "@heroicons/react/24/outline";
import jwt_decode from "jwt-decode";
import axios from "axios";

const DashboardMenuButton = () => {
  const [payload, setPayload] = useState({});
  const [selectedId, sestSelectedId] = useState("")
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editId, setEditId] = useState(1);
  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({
    item: 5,
  });
  const [list, setList] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const header = ["id", "name", "caption", "description", "menu"];
  const bodyMapData = ["name", "caption", "description", "menu_id"];

  const menu = useSelector((state) => state.menu);

  const getToken = async () => {
    const token = localStorage.getItem("authorization");
    const decode = await jwt_decode(token, {
      header: process.env.REACT_APP_ACCESS_TOKEN,
    });
    setPayload(decode);
  };

  const getList = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/menu_buttons/get_all_pagination/${page}`,
      {
        params: {
          ...query,
        },
      }
    );
    setList(result.data.result.rows);
    setTotalCount(result.data.result.count);
  };

  useEffect(() => {
    getToken();
    getList();
  }, []);

  useEffect(() => {
    getList();
  }, [page]);
  return (
    <Fragment>
      <div className="flex">
        <SidebarNew2 opened={opened} setOpened={setOpened} />
        <section
          className={`${
            opened ? "hidden sm:block sm:w-3/4" : "w-full sm:w-11/12"
          } duration-300 `}
        >
          <div className="flex flex-col pt-3 px-7 pb-3 bg-slate-200 items-center sm:items-start sticky top-0 z-10">
            <Bars3Icon
              onClick={() => setOpened(true)}
              className={`${
                !opened
                  ? "text-slate-500 w-7 h-7 absolute left-7 top-6 sm:hidden"
                  : "hidden"
              }`}
            />
            <h1 className="text-2xl font-semibold">Menu Buttons</h1>
            <div className="flex items-center justify-center mt-1">
              <p className="text-xs text-slate-600 font-bold opacity-30 mr-2">
                Group :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30 pr-3 border-2 border-r-slate-600 border-opacity-30">
                {payload.rolegroup}
              </p>
              <p className="text-xs text-slate-600 font-bold opacity-30 mx-2">
                User :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30">
                {payload.name}{" "}
              </p>
            </div>
          </div>
          <div className="px-9 sm:px-14 py-5 h-4/5 relative">
            <div className="flex mb-3">
              <ShowingItems
                setSearchParams={setSearchParams}
                setQuery={setQuery}
                query={query}
                getList={getList}
              />
              <MenuDropDownFilter
                setMenuList={setList}
                setTotalCount={setTotalCount}
                page={page}
                setSearchParams={setSearchParams}
                setQuery={setQuery}
                query={query}
              />
              {menu.create ? (
                <button
                  className="ml-4"
                  onClick={() => setShowModalAdd(!showModalAdd)}
                >
                  <PlusIcon className="icon-style" />
                </button>
              ) : (
                <button className="ml-4 pointer-events-none">
                  <PlusIcon className="text-slate-200 w-5 h-5" />
                </button>
              )}
            </div>
            <MenuButtonsTable
              header={header}
              list={list}
              getList={getList}
              bodyMapData={bodyMapData}
              totalCount={totalCount}
              query={query}
              page={page}
              setPage={setPage}
              setShowModalEdit={setShowModalEdit}
              setEditId={setEditId}
              deleteLink="/menu_buttons/delete/"
            />
          </div>
        </section>
        <Modal isVisible={showModalAdd} onClose={() => setShowModalAdd(false)}>
          <div className="px-6 py-4">
            <MenuButtonForm
              onClose={() => setShowModalAdd(false)}
              getList={getList}
              type="create"
            />
          </div>
        </Modal>
        <Modal isVisible={showModalEdit} onClose={() => setShowModalEdit(false)}>
          <div className="px-6 py-4">
            <MenuButtonForm
              onClose={() => setShowModalAdd(false)}
              getList={getList}
              type="update"
              id={editId}
            />
          </div>
        </Modal>
      </div>
    </Fragment>
  );
};

export default DashboardMenuButton;
