import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import { Modal, Pagination, TableBody, ShowingItems, viewLink } from "../component";

const RegisterTable = ({
  header,
  bodyMapData,
  viewLink,
  editLink,
  getList,
  deleteLink,
  list,
  totalCount,
  setPage,
  page,
  query,
  create,
  read,
  update,
  deleted
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showEditRow, setShowEditRow] = useState();
  const [editFormData, setEditFormData] = useState({});
  const searchQuery = searchParams.get("name")

  const user = useSelector((state) => state.user);
  const menu = useSelector((state) => state.menu);

  // INI
  const handleEdit = async (item) => {
    setShowEditRow(item.id);
    const data = {};
    header.forEach((value) => {
      value === "parent" ?
      data.parent_id = item.parent_id:
      data[value] = item[value];
    });
    setEditFormData(data);
  };

  const handleDelete = async (menuId) => {
    Swal.fire({
      title: "Are you sure you want to delete this menu ?",
      icon: "warning",
      showCancleButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}${deleteLink}${menuId}/${user.userInfo.id}`
        );
        getList();
        Swal.fire(`Menu ${menuId} is deleted`, "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleEditFormChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setEditFormData((prev) => {
      return { ...prev, [fieldName]: fieldValue };
    });
  };

  const handleSave = () => {
    Swal.fire({
      title: "Are you sure you want to commit change ?",
      icon: "warning",
      showCancleButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}${editLink}${editFormData.id}/${user.userInfo.id}`,
          editFormData
        );
        getList();
        Swal.fire(result.data.message, "", "success");
        setShowEditRow("");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
        setShowEditRow("");
      }
    });
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure you want to cancel the update? ",
      text: "Any unsaved changes will be lost.",
      icon: "warning",
      showCancleButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire("Changes are not saved", "", "info");
        setShowEditRow("");
      }
    });
  };

  function TableHead({ head }) {
    return (
      <th key={head} scope="col" className="px-6 py-3">
        {head}
      </th>
    );
  }
  
  if(!Array.isArray(list)) {
    return <div>Loading...</div>
  }
  return (
    <div className="register-button-div h-[600px] sm:h-[460px] ">
      <div className="table-div">
        <form>
          <table className="table-table">
            <thead className="table-thead sticky top-0">
              <tr>
                {header.map((item) => (
                  <TableHead head={item} />
                ))}
                <th className="sticky right-0 bg-slate-100">Action</th>
              </tr>
            </thead>
            <tbody>
              <TableBody
                list={list}
                editFormData={editFormData}
                handleEditFormChange={handleEditFormChange}
                handleSave={handleSave}
                handleCancel={handleCancel}
                showEditRow={showEditRow}
                bodyMapData={bodyMapData}
                handleEdit={handleEdit}
                menu={menu}
                handleDelete={handleDelete}
                viewLink={viewLink}
                create={create}
                read={read}
                update={update}
                deleted={deleted}
              />
            </tbody>
          </table>
        </form>
      </div>
      {console.log("isi query", query)}
      <Pagination
      itemsPerPage={query.item}
        itemsInPage={list.length}
        totalItems={totalCount}
        totalPages={Math.ceil(totalCount / parseInt(query.item))}
        currentPage={page}
        setCurrentPage={setPage}
      />
    </div>
  );
};

export default RegisterTable;
