import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { successAlert, errorAlertWithMessage } from "../../../helper/alert";
import axios from "axios";
import * as Yup from "yup";

const UserButtonCreateForm = ({ onClose, getList, type, id }) => {
  const user = useSelector((state) => state.user);
  const list = useSelector((state) => state.list);
  const [menuList, setMenuList] = useState([]);

  const getMenuRoleSecurityList = async () => {
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/role_user_button_security/get_one/" + id
    );

    formik.setValues({
      menubutton_id: result.data.result.menubutton_id,
    });
  };

  const getMenuList = async () => {
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/menu_buttons/get_all"
    );
    setMenuList(result.data.result);
  };

  useEffect(() => {
    getMenuList();

    if (type === "update") {
      getMenuRoleSecurityList();
    }
  }, []);

  // ========================================================================

  const userId = user.userInfo.id;
  const formik = useFormik({
    initialValues: {
      menubutton_id: "",
    },
    onSubmit: async (values) => {
      const data = {};
      data.menubutton_id = formik.values.menubutton_id;
      try {
        if (type === "create") {
          const result = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/role_user_button_security/register/` +
              userId +
              "/" +
              list.list.listId,
            data
          );
          await successAlert(result.data.message);
          onClose();
          getList();
        } else if (type === "update") {
          const result = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/role_user_button_security/update/${id}/${user.userInfo.id}`,
            data
          );
          await successAlert(result.data.message);
          onClose();
          getList();
        }
      } catch (error) {
        await errorAlertWithMessage(error?.response?.data?.message);
      }
    },
    validationSchema: Yup.object({
      // name: Yup.string().required('Required'),
      // level: Yup.string().email('Invalid email format').required("Required"),
    }),
  });
  return (
    <div className="flex flex-col">
      <p className="text-left text-lg font-semibold border-b pb-2 mb-2">
        Register New Menu
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid gap-4 sm:grid-cols-4 sm:gap-x-6 sm:gap-y-2">
          <div className="sm:col-span-4">
            <label htmlFor="name" className="input-label">
              Menu
            </label>
            <select
              className="input-field"
              id="menubutton_id"
              name="menubutton_id"
              {...formik.getFieldProps("menubutton_id")}
              value={formik.values.menubutton_id} // Make sure you set the selected value
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.setFieldValue("menubutton_id", selectedValue); // Set the selected value
              }}
            >
              <option value="" disabled selected>
                Select menu
              </option>
              {menuList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.menu.name}, {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 text-white rounded-md mt-6 py-2 font-bold text-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default UserButtonCreateForm;
