import React, { useState } from 'react';
import { Button, Card, Checkbox, Label, TextInput } from 'flowbite-react';
import axios from "axios";
import { successAlert, errorAlertWithMessage } from '../helper/alert';
import { useNavigate, Link} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login } from'../reducers/userSlice'


const Login = () => {
  const [nik, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch()

  const navigate = useNavigate();

  const onLogin = async (e) => {
    e.preventDefault();
    const data = {
      nik,
      password,
    };
    try {
      const result = await axios.post( `${process.env.REACT_APP_BASE_URL}/role_user/login`, data);
      const server = await axios.get(`${process.env.REACT_APP_BASE_URL}/maintanance/`)

      const roleGroup =  result.data.result.role_group.name
      const serverStatus = server.data.result[0].status

      if(roleGroup === "admin") {
        localStorage.setItem("authorization",result.data.access)
        dispatch(login(result.data))
        await successAlert(result.data.message);
        navigate("/home")
      } else if (roleGroup !== "admin" && serverStatus === false){
        navigate("/maintanance")
      } else {
        localStorage.setItem("authorization",result.data.access)
        dispatch(login(result.data))
        await successAlert(result.data.message);
        navigate("/home")
      }
      // Handle success
      
    } catch (error) {
      // Handle errors
      console.error(error);
      errorAlertWithMessage(error?.response?.data?.message); // You can implement your own error handling here
    }
  };

  return (
    <Card className=' container-screen sm:px-20'>
      <form className="flex flex-col gap-4" onSubmit={onLogin}>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="email1"
              value="NIK"
            />
          </div>
          <TextInput
            id="email1"
            placeholder="nik"
            required
            type="text"
            value={nik}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="password1"
              value="Password"
            />
          </div>
          <TextInput
            id="password1"
            placeholder='password'
            required
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Button
          type="submit"
          className="login-button"
        >
          Login
        </Button>
      </form>
    </Card>
  );
};

export default Login;
