import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Pagination } from "../../component";
import {
  EyeIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import axios from "axios";

const RoleGroupButtonSecurityTable = ({
  header,
  list,
  query,
  page,
  totalCount,
  setPage,
  setShowModalEdit,
  setSelectedId,
  getList,
  deleteLink,
}) => {
  const user = useSelector((state) => state.user);
  const menu = useSelector((state) => state.menu);

  function TableHead({ head }) {
    return (
      <th scope="col" class="px-6 py-3">
        {head}
      </th>
    );
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this menu ?",
      icon: "warning",
      showCancleButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}${deleteLink}/${id}/${user.userInfo.id}`
        );
        getList();
        Swal.fire(`Menu ${id} is deleted`, "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  if (!Array.isArray(list)) {
    return <div>Loading...</div>;
  }

  return (
    <div className="register-button-div ">
      <div className="table-div">
        <table class="table-table">
          <thead class="table-thead sticky top-0">
            <tr>
              {header.map((item) => (
                <TableHead head={item} />
              ))}
              <th className="sticky right-0 bg-slate-100">Action</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item) => (
              <tr className="border-b">
                <th scope="row" className="user-info">
                  {item.id}
                </th>
                <td
                  className={`px-1
                  py-2 text-base`}
                >
                  {item.menubutton.menu.name}
                </td>
                <td
                  className={`px-1
                  py-2 text-base`}
                >
                  {item.menubutton.name}
                </td>

                <td className="px-6 py-4 sticky right-0 bg-white flex">
                  <button
                    onClick={() => {
                      setShowModalEdit(true);
                      setSelectedId(item.id);
                    }}
                    className={
                      menu.update
                        ? "bg-yellow-400 rounded-md text-white p-2 w-7 h-7 mr-2"
                        : "bg-slate-500 bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
                    }
                  >
                    <PencilSquareIcon />
                  </button>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete(item.id);
                    }}
                    className={
                      menu.delete
                        ? "bg-red-500 rounded-md text-white p-2 w-7 h-7"
                        : "bg-slate-500 bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
                    }
                  >
                    <TrashIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsInPage={list.length}
        totalItems={totalCount}
        totalPages={Math.ceil(totalCount / parseInt(query.item))}
        currentPage={page}
        setCurrentPage={setPage}
      />
    </div>
  );
};

export default RoleGroupButtonSecurityTable;
