import React, { useState, useEffect } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { SidebarNew2 } from "../component";
import jwt_decode from "jwt-decode";
import Truck from "../assets/truck.jpg";

const HomePage = () => {
  const [payload, setPayload] = useState({});
  const [opened, setOpened] = useState(false);

  const getToken = async () => {
    const token = localStorage.getItem("authorization");
    const decode = await jwt_decode(token, {
      header: process.env.REACT_APP_ACCESS_TOKEN,
    });
    setPayload(decode);
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div className="flex relative">
      <SidebarNew2 opened={opened} setOpened={setOpened} />
      <section
        className={`${
          opened ? "hidden sm:block sm:w-3/4 " : "w-full sm:w-11/12"
        } duration-300 bg-yellow-400`}
      >
        <div className="flex flex-col pt-3 px-7 pb-3 bg-slate-200 items-center sm:items-start sticky top-0">
          <Bars3Icon
            onClick={() => setOpened(true)}
            className={`${
              !opened
                ? "text-slate-500 w-7 h-7 absolute left-7 top-6 sm:hidden"
                : "hidden"
            }`}
          />
          <h1 className="text-xl sm:text-2xl font-semibold w-3/4 sm:w-auto">
            Home Page{" "}
          </h1>
          <div className="flex items-center justify-center mt-1">
            <p className="text-xs text-slate-600 font-bold opacity-30 mr-2">
              Group :
            </p>
            <p className="text-xs text-slate-600 font-semibold opacity-30 pr-3 border-2 border-r-slate-600 border-opacity-30">
              {payload.rolegroup}
            </p>
            <p className="text-xs text-slate-600 font-bold opacity-30 mx-2">
              User :
            </p>
            <p className="text-xs text-slate-600 font-semibold opacity-30">
              {payload.name} {/*  */}
            </p>
          </div>
        </div>
        <div
          className="h-full sm:h-5/6 flex flex-col"
          // style={{ backgroundImage: `url(${Truck})` }}
        >
          <div className="bg-white h-full  grid sm:grid-cols-2">
            <div className="flex flex-col justify-center items-center bg-red-600 border-b-2 border-white sm:border-none ">
              <p className="text-3xl sm:text-6xl font-bold text-white drop-shadow-md">
                WELCOME TO
              </p>
              <p className="mt-3 font-semibold text-yellow-300 text-xl sm:text-3xl drop-shadow-md">
                Sharepoint
              </p>
            </div>
            <div className="flex flex-col justify-center items-center p-4 ">
              <p className="text-sm sm:text-lg">
                Silahkan pilih menu di kolom samping atau pencet tombol di bawah
                ini
              </p>
              <button className="mt-4 p-3 bg-red-500 rounded-md text-white font-semibold hover:bg-red-600 transition duration-300"
              onClick={() => setOpened(!opened)}>
                Buka Menu
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
