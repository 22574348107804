import React,{Fragment, useState} from 'react'
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline'
import { Transition } from "@headlessui/react"
import axios from 'axios'

const MenuDropDownFilter = ({setMenuList, setTotalCount, page, setSearchParams, setQuery, query}) => {
    const [isOpen, setIsOpen] = useState(false)
  
    const handleQueryChange = (e) => {
        setQuery((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      };

      const handleSubmit = async () => {
        setIsOpen(!isOpen)
        setSearchParams(query)
        const result = await axios.get(
            "http://localhost:8000/menu/get/" + page 
          ,{
            params: { 
                ...query
            }
          }) ;
          setMenuList(result.data.result.rows);
          setTotalCount(result.data.result.count);
      }
      
    
  return (
    <Fragment>
        <button onClick={() => setIsOpen(!isOpen)}>
            <MagnifyingGlassIcon className="w-5 h-5 text-slate-400 hover:text-slate-950 duration-300"/>
        </button>
        <Transition
        show={isOpen}
        >
            <div className='filter-dropdown-box'>
                <h1 className='text-red-700 text-lg pt-2 pb-1 font-semibold text-left border-b-2 border-yellow-400'>Filter</h1>
                <div>
                    <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    className="text-left grid grid-cols-2 gap-2 py-3"
                    >
                        <div className='col-span-2'>
                            <label>Name :</label>
                            <input
                                onChange={handleQueryChange}
                                placeholder="Search menu name"
                                type="search"
                                name="name"
                                className="input-field"
                                value={query.name}
                            />
                        </div>                                  
                    </form>
                    <button 
                    className='blue-button ml-auto'
                    onClick={() => {handleSubmit()}}
                    >Apply Filter</button>
                </div>
            </div>
        </Transition>
     
    </Fragment>
  )
}

export default MenuDropDownFilter