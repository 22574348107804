import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import menuReducer from "./reducers/menuSlice";
import listReducer from "./reducers/listSlice";
import databaseReducer from "./reducers/databaseSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, PERSIST } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  user: userReducer,
  menu: menuReducer,
  list: listReducer,
  database: databaseReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root')
    return reducer(undefined, action)
  }
  return reducer(state,action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [PERSIST],
      },
    }),
});
