import React from 'react'

const EditRow = ({
    editFormData, 
    handleEditFormChange, 
    key, 
    bodyMapData, 
    handleSave, 
    handleCancel 
}) => {
  return (
    <tr key={key}>
      <th className="px-6 py-2">{editFormData.id}</th>
      {bodyMapData.map((item) => (
        <td className="px-6 py-2">
          <input
            type={
              item === "urut" || item === "level" || item === "parent_id"
                ? "number"
                : "text"
            }
            placeholder={item}
            name={item}
            className="input-field"
            onChange={handleEditFormChange}
            value={editFormData[item]}
            disabled = {item === "parent_id" || item === "level" || item === "nik" ? true : false}
          />
        </td>
      ))}
      {console.log("cek isi dari editFormData", editFormData)}
      <td className="px-6 py-4 sticky right-0 bg-white flex">
        <button
          onClick={(e) => {
            e.preventDefault();
            handleSave();
          }}
          className="save-button-table"
        >
          Save
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
          className="cancle-button-table"
        >
          Cancel
        </button>
      </td>
    </tr>
  )
}

export default EditRow