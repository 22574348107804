import React from "react";
import { useNavigate } from "react-router-dom";

const MaintanancePage = () => {
    const navigate = useNavigate()
  return (
    <div className="bg-white w-screen h-screen flex flex-col items-center justify-center">
      <p className="font-bold text-3xl text-yellow-300 mb-4">SERVER SEDANG DALAM PERBAIKAN</p>
      <button className="blue-button" onClick={() => navigate("/")}>Kembali ke halaman login</button>
    </div>
  );
};

export default MaintanancePage;
