import React from "react";

const SearchBar = ({ search, setSearch, placeholder }) => {
  return (
    <div>
      <form>
        <input
          placeholder={placeholder}
          className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-yellow-400 focus:border-yellow-400 block w-auto ml-3 p-2.5
    disabled:bg-gray-200 disabled:border-none"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </form>
    </div>
  );
};

export default SearchBar;
