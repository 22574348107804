import React from "react";
import { ShowingItems } from "../../../component";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import {
  MonthFilter,
  YearFilter,
  CabangFilter,
  DatePickerFilter,
} from "../../../component/filter";

const MobileFilterComponent = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCabang,
  setSelectedCabang,
}) => {
  return (
    <div className="relative mb-3">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white">
              <span>Filter</span>
              <ChevronUpIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-white ml-4`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="flex flex-col justify-start items-start px-4 pt-4 pb-2 text-sm text-gray-500 border border-slate-400 bg-white rounded-sm">
              <DatePickerFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                selectedCabang={selectedCabang}
              />
              <p className="text-sm font-bold mt-3">Cabang:</p>
              <CabangFilter
                startDate={startDate}
                endDate={endDate}
                setSelectedCabang={setSelectedCabang}
                selectedCabang={selectedCabang}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default MobileFilterComponent;
