import React from "react";
import { Login, LoginNavbar } from "../component";
import { useDispatch } from "react-redux";

const LoginPage = () => {
  return (
    <>
      <LoginNavbar />
      <h1 className="text-3xl py-9">Silahkan Login ke Akun Anda</h1>
      <div className="sm:px-44">
        <Login />
      </div>
    </>
  );
};

export default LoginPage;
