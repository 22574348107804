import React, { Fragment, useState, useEffect } from "react";
import { SidebarNew2, ShowingItemsEpicor, SearchBar } from "../../../component";
import { ReportTrackingDetailsTable } from "../../../component/table/report_tracking_so";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ShowingItems, Modal } from "../../../component";
import { Bars3Icon } from "@heroicons/react/24/outline";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useSelector } from "react-redux";
import { BounceLoader } from "react-spinners";

const DetailsReportingTrackingSO = () => {
  const [payload, setPayload] = useState({});
  const [loading, setLoading] = useState(true);
  const [showing, setShowing] = useState(5);
  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState({
    item: 5,
  });
  const [list, setList] = useState([]);
  const [sliceList, setSliceList] = useState([]);

  // UNTUK SLICING DARI LIST
  const [sliceStart, setSliceStart] = useState(0);
  const [sliceEnd, setSliceEnd] = useState(5);

  const [totalCount, setTotalCount] = useState(5);
  const listItem = useSelector(
    (state) => state.list.list.universal.UD20_Date01
  );
  const cabang = useSelector((state) => state.list.list.cabang);

  const changeSlice = async () => {
    await setSliceStart(0);
    await setSliceEnd(parseInt(showing));
  };

  const paginationChange = () => {
    setSliceEnd(page * showing);
    setSliceStart(page * showing - showing);
  };

  const filterList = () => {
    const hasil = list.filter((item) => {
      return item.UD21_Key2.includes(search);
    });
    setSliceList(hasil.slice(sliceStart, sliceEnd));
    setTotalCount(hasil.length);
  };

  const getToken = async () => {
    const token = localStorage.getItem("authorization");
    const decode = await jwt_decode(token, {
      header: process.env.REACT_APP_ACCESS_TOKEN,
    });
    setPayload(decode);
  };

  const getList = async () => {
    const config = {
      auth: {
        username: process.env.REACT_APP_USER_EPICOR,
        password: process.env.REACT_APP_PASSWORD_EPICOR,
      },
    };

    try {
      const result = await axios.get(
        `https://ns2.adyaarthaabadi.com/AAAEpicorErp/api/v1/BaqSvc/AAA_R2_SOSerah_DTL/?Plant=${cabang}&TglSO=${listItem}`,
        config
      );
      setLoading(false);
      setList(result.data.value);
      setSliceList(result.data.value.slice(sliceStart, sliceEnd));
      setTotalCount(result.data.value.length);
    } catch (error) {
      alert("Terjadi kesalahan saat mengambil data");
    }
  };

  useEffect(() => {
    getToken();
    getList();
  }, []);

  useEffect(() => {
    setSliceList(list.slice(sliceStart, sliceEnd));
  }, [sliceStart, sliceEnd]);

  useEffect(() => {
    changeSlice();
  }, [showing]);

  useEffect(() => {
    paginationChange();
  }, [page]);

  useEffect(() => {
    filterList();
  }, [search]);

  return (
    <Fragment>
      <div className="flex">
        <SidebarNew2 opened={opened} setOpened={setOpened} />
        <section
          className={`${
            opened ? "hidden sm:block sm:w-3/4" : "w-full sm:w-11/12"
          } duration-300 `}
        >
          <div className="flex flex-col pt-3 px-7 pb-3 bg-slate-200 items-center sm:items-start sticky top-0">
            <Bars3Icon
              onClick={() => setOpened(true)}
              className={`${
                !opened
                  ? "text-slate-500 w-7 h-7 absolute left-7 top-6 sm:hidden"
                  : "hidden"
              }`}
            />
            <h1 className="text-2xl font-semibold">Report Tracking SO </h1>
            <div className="flex items-center justify-center mt-1">
              <p className="text-xs text-slate-600 font-bold opacity-30 mr-2">
                Group :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30 pr-3 border-2 border-r-slate-600 border-opacity-30">
                {payload.rolegroup}
              </p>
              <p className="text-xs text-slate-600 font-bold opacity-30 mx-2">
                User :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30">
                {payload.name}{" "}
              </p>
            </div>
          </div>
          <div className="px-9 sm:px-14 py-5 h-4/5 relative">
            <div className="flex">
              <ShowingItemsEpicor setShowing={setShowing} />
              <SearchBar setSearch={setSearch} search={search} placeholder={"Search SO Number"} />
            </div>
            {loading ? (
              <div className="flex items-center justify-center mt-10">
                <BounceLoader color="#4A84FF" />
              </div>
            ) : (
              <ReportTrackingDetailsTable
                totalCount={totalCount}
                query={query}
                page={page}
                setPage={setPage}
                list={sliceList}
                showing={showing}
              />
            )}
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default DetailsReportingTrackingSO;
