import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TrashIcon,
  PencilSquareIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { selectedList } from "../../../reducers/listSlice";
import { konversiTanggal } from "../../../helper/DatabaseDateConverter";
import { shipPercentage } from "../../../helper/shipCompletion";

const ReportTrackingLinkTable = ({
  list,
  update,
  deleted,
  view,
  viewLink,
  editLink,
}) => {
  const menu = useSelector((state) => state.menu);
  const cabang = useSelector((state) => state.list.list.cabang)
  const dispatch = useDispatch();

  const handleStateUpdate = (item) => {
    dispatch(selectedList(item));
  };

  function isInteger(str) {
    if (str === null || str === undefined) {
      return true;
    }

    return /^-?\d+$/.test(str);
  }

  if (!Array.isArray(list)) {
    return <div>Loading...</div>;
  }
  return (
    <div className="register-button-div h-[500px] sm:h-[400px] mt-2">
      <div className="table-div">
        <form>
          <table className="table-table">
            <thead className="table-thead sticky top-0 border">
              <tr>
                <th rowSpan={2} className="px-6 py-1 border">
                  DATE
                </th>
                <th rowSpan={2} className="px-6 py-1 border">
                  SO
                </th>
                <th rowSpan={2} className="px-6 py-1 border">
                  SHIPMENT
                </th>
                <th rowSpan={2} className="px-6 py-1 border">
                  %
                </th>
                <th
                  colSpan={4}
                  className="px-6 py-1 border text-center border-slate-300 bg-yellow-300"
                >
                  SERAH
                </th>
                <th
                  rowSpan={2}
                  className="px-6 py-1 border bg-blue-500 text-white text-center"
                >
                  ACTION
                </th>
              </tr>
              <tr>
                <th className="px-6 py-1 border border-slate-200 bg-yellow-300">
                  CS-GUDANG
                </th>
                <th className="px-6 py-1 border border-slate-200 bg-yellow-300">
                  RO-GUDANG
                </th>
                <th className="px-6 py-1 border border-slate-200 bg-yellow-300">
                  PENGANTAR
                </th>
                <th className="px-6 py-1 border border-slate-200 bg-yellow-300">
                  BALIK
                </th>
              </tr>
            </thead>

            <tbody>
              {list.map((item) => (
                <tr className="hover:bg-slate-200">
                  {item.UD20_Date01 && (
                    <th scope="row" className="user-info border">
                      {konversiTanggal(item.UD20_Date01)}
                    </th>
                  )}

                  <td
                    className={
                      isInteger(item.Calculated_OrderValid)
                        ? "text-right px-6 border py-2 text-base"
                        : "px-6 py-2 text-base border"
                    }
                  >
                    {item.Calculated_OrderValid
                      ? item.Calculated_OrderValid
                      : 0}
                  </td>
                  <td
                    className={
                      isInteger(item.Calculated_ShipValid)
                        ? "text-right px-6 border py-2 text-base"
                        : "px-6 py-2 text-base border"
                    }
                  >
                    {item.Calculated_ShipValid ? item.Calculated_ShipValid : 0}
                  </td>
                  <td
                    className={
                      isInteger(item.Calculated_ShipValid)
                        ? "text-right px-6 border py-2 text-base"
                        : "px-6 py-2 text-base border"
                    }
                  >
                    {item.Calculated_OrderValid && item.Calculated_ShipValid
                      ? shipPercentage(
                          item.Calculated_OrderValid,
                          item.Calculated_ShipValid
                        )
                      : 0}
                  </td>
                  <td
                    className={
                      isInteger(item.Calculated_GudangValid)
                        ? "text-right px-6 border py-2 text-base"
                        : "px-6 py-2 text-base border"
                    }
                  >
                    {item.Calculated_GudangValid
                      ? item.Calculated_GudangValid
                      : 0}
                  </td>
                  <td
                    className={
                      isInteger(item.Calculated_GudangValid)
                        ? "text-right px-6 border py-2 text-base"
                        : "px-6 py-2 text-base border"
                    }
                  >
                    {item.Calculated_GudangValid
                      ? item.Calculated_GudangValid
                      : 0}
                  </td>
                  <td
                    className={
                      isInteger(item.Calculated_PengantarValid)
                        ? "text-right px-6 border py-2 text-base"
                        : "px-6 py-2 text-base border"
                    }
                  >
                    {item.Calculated_PengantarValid
                      ? item.Calculated_PengantarValid
                      : 0}
                  </td>
                  <td
                    className={
                      isInteger(item.Calculated_BalikValid)
                        ? "text-right px-6 border py-2 text-base"
                        : "px-6 py-2 text-base border"
                    }
                  >
                    {item.Calculated_BalikValid
                      ? item.Calculated_BalikValid
                      : 0}
                  </td>

                  <td className="px-6 py-4 border bg-white flex items-center justify-center">
                    {view === true && (
                      <Link
                        to={viewLink}
                        state={{ menu: item.id, menu_name: item.name }}
                        onClick={() =>
                          dispatch(
                            selectedList({
                              cabang: cabang,
                              general: item,
                            })
                          )
                        }
                      >
                        <button className="bg-blue-500 rounded-md hover:bg-blue-600 transition duration-300 text-white p-2 w-7 h-7 mr-2">
                          <EyeIcon />
                        </button>
                      </Link>
                    )}
                    {update === true && (
                      <Link
                        to={editLink}
                        state={{ menu: item.id, menu_name: item.name }}
                        onClick={() => handleStateUpdate(item)}
                        className={menu.update ? "" : "pointer-events-none"}
                      >
                        <button
                          onClick={() => {}}
                          className={
                            menu.update
                              ? "bg-yellow-400 rounded-md text-white p-2 w-7 h-7 mr-2"
                              : "bg-slate-500  bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
                          }
                        >
                          <PencilSquareIcon />
                        </button>
                      </Link>
                    )}
                    {deleted === true && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className={
                          menu.delete
                            ? "bg-red-500 rounded-md text-white p-2 w-7 h-7"
                            : "bg-slate-500 bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
                        }
                      >
                        <TrashIcon />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>
      </div>
      {/* <Pagination
        itemsInPage={list.length}
        totalItems={totalCount}
        totalPages={Math.ceil(totalCount / parseInt(query.item))}
        currentPage={page}
        setCurrentPage={setPage}
      /> */}
    </div>
  );
};

export default ReportTrackingLinkTable;
