import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({
    itemsPerPage,
    itemsInPage,
    totalItems,
    totalPages = 0,
    currentPage,
    setCurrentPage,
}) => {
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = startItem + itemsInPage - 1

  useEffect(() => {
    if (totalPages && currentPage > totalPages) setCurrentPage(totalPages);
  }, [currentPage, setCurrentPage, totalPages])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  }

  if (totalPages === 0) return null;

  return (
    <>
      <div className="flex items-center justify-between w-full">
        <p className=" text-gray-700 ">
          Showing <span className="font-bold">{startItem}</span> to{" "}
          <span className="font-bold">{endItem}</span> of{" "}
          <span className="font-bold">{totalItems}</span> {totalItems == 1 ? "result" : "results"}
        </p>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          forcePage={currentPage - 1}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          previousLabel="<"
          renderOnZeroPageCount={null}
          className="react-paginate"
          pageClassName="paginate-page-num-container"
          breakLinkClassName="paginate-break"
          pageLinkClassName="paginate-page-num"
          previousLinkClassName="paginate-prev-btn"
          nextLinkClassName="paginate-next-btn"
          activeClassName="paginate-active"
        />
      </div>
    </>
  )
}

export default Pagination