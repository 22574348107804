import React, { useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import { Pagination } from "..";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { formatRoleGroup } from "../../helper/capital_leter"

const MenuButtonsTable = ({
  header,
  list,
  totalCount,
  query,
  page,
  setPage,
  setShowModalEdit,
  setEditId,
  deleteLink,
  getList,
}) => {
  const user = useSelector((state) => state.user);
  const menu = useSelector((state) => state.menu);

  function TableHead({ head }) {
    return (
      <th scope="col" class="px-6 py-3">
        {head}
      </th>
    );
  }

  const handleDelete = async (roleUserId) => {
    Swal.fire({
      title: "Are you sure you want to delete this menu ?",
      icon: "warning",
      showCancleButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}${deleteLink}${roleUserId}/${user.userInfo.id}`
        );
        getList();
        Swal.fire(`Menu ${roleUserId} is deleted`, "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  if (!Array.isArray(list)) {
    return <div>Loading ya...</div>;
  }
  return (
    <div className="register-button-div h-[600px] sm:h-[460px] ">
      <div class="table-div">
        <form>
          <table class="table-table">
            <thead class="table-thead sticky top-0">
              <tr>
                {header.map((item) => (
                  <TableHead head={item} />
                ))}
                <th className="sticky right-0 top-0  bg-slate-100">Action</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item) => (
                <tr
                  className="bg-white border dark:bg-gray-900 dark:border-gray-700"
                  key={item.id}
                >
                  <th scope="row" className="user-info">
                    {item.id}
                  </th>
                  <td className="px-6 py-2 border">{item.name}</td>
                  <td className="px-6 py-2 border">{item.caption}</td>
                  <td className="px-6 py-2 border">{item.description}</td>
                  <td className="px-6 py-2 border">{item?.menu?.name}</td>
                  <td className="px-6 py-4 sticky right-0  bg-white flex">
                    {/* <button className="bg-blue-500 rounded-md text-white p-2 w-7 h-7 mr-2">
                      <EyeIcon />
                    </button> */}

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModalEdit(true);
                        setEditId(item.id);
                      }}
                      className={
                        menu.update
                          ? "bg-yellow-400 rounded-md text-white p-2 w-7 h-7 mr-2"
                          : "bg-slate-500 bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
                      }
                    >
                      <PencilSquareIcon />
                    </button>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(item.id);
                      }}
                      className={
                        menu.delete
                          ? "bg-red-500 rounded-md text-white p-2 w-7 h-7"
                          : "bg-slate-500 bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
                      }
                    >
                      <TrashIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>
      </div>
      <Pagination
        itemsInPage={list.length}
        totalItems={totalCount}
        totalPages={Math.ceil(totalCount / parseInt(query.item))}
        currentPage={page}
        setCurrentPage={setPage}
      />
    </div>
  );
};

export default MenuButtonsTable;
