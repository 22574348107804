import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCabang,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="flex sm:flex-row flex-col items-center">
      <p className="mr-3">From</p>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          setSearchParams({
            start: date,
            end: endDate,
            cabang: selectedCabang,
          });
        }}
        dateFormat="dd-MM-yyyy"
        showYearDropdown
        scrollableMonthYearDropdown
        className="rounded-lg h-8 w-36 text-center"
      />

      <p className="mx-3">To</p>
      <DatePicker
        selected={endDate}
        onChange={(date) => {
          setEndDate(date);
          setSearchParams({
            start: startDate,
            end: date,
            cabang: selectedCabang,
          });
        }}
        dateFormat="dd-MM-yyyy"
        showYearDropdown
        scrollableMonthYearDropdown
        className="rounded-lg h-8 w-36 text-center"
      />
    </div>
  );
};

export default DatePickerFilter;
