import React, { useEffect, useState } from 'react'
import { useSelector} from 'react-redux'
import { useFormik } from 'formik'
import { successAlert, errorAlertWithMessage } from '../../../helper/alert'
import axios from 'axios'
import * as Yup from 'yup'

const RoleUserForm = ({onClose}) => {
    const user = useSelector((state) => state.user)
    const userId = user.userInfo.id
    const [role, setRole] = useState([
        {
            name:""
        }
    ])

    const roleGroup = async() => {
        const result = await axios.get(process.env.REACT_APP_BASE_URL + "/role_group/get_all")
        setRole(result.data.result)
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            nik: "",
            email:"",
            password:"",
            rolegroup_id:"",
            image:""
        },
        onSubmit: async(values) => {
            const userEdit = new FormData()
            userEdit.append("name", values.name)
            userEdit.append("nik", values.nik)
            userEdit.append("email", values.email)
            userEdit.append("password", values.password)
            userEdit.append("rolegroup_id", values.rolegroup_id)
            userEdit.append("image", values.image)
            try {
                const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/role_user/register_user/` + userId, userEdit)
                await successAlert(result.data.message)
                onClose()
            } catch (error) {
                console.log("register error", error)
                await errorAlertWithMessage(error?.response?.data?.message)
            }      
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email format').required("Required"),
            password: Yup.string().required('Required')
        })
    })

    useEffect(() => {
        roleGroup()
    },[])

  return (
    <div className='flex flex-col'>
        <p className='text-left text-lg font-semibold border-b pb-2 mb-2'>Register New User Role</p>
        <form onSubmit={formik.handleSubmit}>
            <div className='grid gap-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-2'>
                <div className="sm:col-span-2">
                    <label htmlFor='name' className="input-label">Name</label>
                    <input type="text"className="input-field" id="name" name="name" {...formik.getFieldProps('name')}/>
                    {formik.touched.name && formik.errors.name ? (
                        <div className='text-red-500 text-left text-xs font-semibold'>{formik.errors.name}</div>
                    ):null}
                </div>
                <div>
                    <label htmlFor='password' className='input-label'>Password</label>
                    <input type="password" className='input-field' id="password" name="password" onChange={formik.handleChange} value={formik.values.password} onBlur={formik.handleBlur}/>
                    {formik.touched.password && formik.errors.password ? (
                        <div className='text-red-500 text-left text-xs font-semibold'>{formik.errors.password}</div>
                    ) : null}
                </div>
                <div>
                    <label htmlFor='email' className='input-label'>E-mail</label>
                    <input type="email" className="input-field" id="email" name="email" {...formik.getFieldProps('email')}/>
                    {formik.touched.email && formik.errors.email ? (
                        <div className='text-red-500 text-left text-xs font-semibold'>{formik.errors.email}</div>
                    ):null}
                </div>
                <div>
                    <label htmlFor='name' className='input-label'>Nik</label>
                    <input type="text" className='input-field' id="nik" name="nik" onChange={formik.handleChange} value={formik.values.nik}/>
                </div>               
                <div>
                    <label htmlFor='rolegroup_id' className='input-label'>Role Group</label>
                    <select
                    className="input-field"
                    id="rolegroup_id"
                    name="rolegroup_id"
                    {...formik.getFieldProps('rolegroup_id')}
                    value={formik.values.rolegroup_id} // Make sure you set the selected value
                    onChange={(e) => {
                        const selectedValue = e.target.value;
                        formik.setFieldValue('rolegroup_id', selectedValue); // Set the selected value
                    }}
                    >
                        <option value="" disabled selected>Select parent</option>
                    {role.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
                </div>     
                <div className='sm:col-span-2'>
                    <label htmlFor='name' className='input-label'>Avatar</label>
                    <input type="file" className='block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400' id="image" name="image" onChange={(e) => {formik.setFieldValue('image', e.currentTarget.files[0])}}/>
                </div>     
            </div>
            <button type='submit' className='w-full bg-green-500 text-white rounded-md mt-6 py-2 font-bold text-md'>Submit</button>
        </form>
    </div>
  )
}

export default RoleUserForm