import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  DocumentIcon,
  DocumentDuplicateIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import CompanyLogo from "../assets/logo perusahaan.png";
import { Disclosure } from "@headlessui/react";
import CompanyLogoWhite from "../assets/logo_icon_putih.png";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { urlModifier } from "../helper/url_modifier";
import { formatRoleGroup } from "../helper/capital_leter";
import { menuAccess } from "../reducers/menuSlice";
import { database } from "../reducers/databaseSlice";

const SidebarNew = ({ opened, setOpened }) => {
  const [menu, setMenu] = useState([]);
  const [server, setServer] = useState("");
  const user = useSelector((state) => state.user);
  const accessMenu = useSelector((state) => state.menu);
  const databases = useSelector((state) => state.database.database);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getMenu = async () => {
    const token = await localStorage.getItem("authorization");
    const decoded = jwt_decode(token, {
      header: process.env.REACT_APP_ACCESS_TOKEN,
    });
    const userId = decoded.userId;
    const roleGroup = decoded.roleGroupId;
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/menu/get/" + userId + "/" + roleGroup
    );
    setMenu(result.data.result);
  };

  const getServer = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/maintanance/`
    );
    setServer(result.data.result[0].status);
  };

  const onServer = async (p) => {
    const data = await {
      status: p,
    };

    const confirmationMessage = (await p) === true ? "turn on" : "turn off";

    Swal.fire({
      title: `Are you sure you want to ${confirmationMessage} the server ?`,
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/maintanance/1/${user.userInfo.id}`,
          data
        );
        getServer();
      } else if (result.isDenied) {
        // User denied the action
      }
    });
  };

  const onLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout ?",
      icon: "warning",
      showCancleButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("authorization");
        window.history.replaceState(null, null, "/");
        navigate("/");
        dispatch({ type: "USER_LOGOUT" });
      } else if (result.isDenied) {
      }
    });
  };

  const onReduxMenu = (item) => {
    dispatch(
      menuAccess({
        create: item[0].create,
        read: item[0].read,
        update: item[0].update,
        delete: item[0].delete,
      })
    );
  };

  function MenuDropdown({ item, levels, menu, parentId }) {
    const uniqueKey = `${item.id}-${levels}`;
    /*--const url digunakan untuk men-convert nama menu menjadi endpoint--*/
    const url = urlModifier(item.name);
    if (menu.filter((e) => e.parent_id === item.id).length == 0) {
      return (
        <Link
          key={uniqueKey}
          to={`/${url}`}
          className="mt-2 hover:bg-red-300 duration-300"
          onClick={() => {
            onReduxMenu(item.role_group_menu_securities);
            setOpened(false);
          }}
        >
          <div key={uniqueKey} className="navbar-child-container">
            <DocumentIcon className="w-5 h-5 text-white" />
            <p className="navbar-child-text">{formatRoleGroup(item.name)}</p>
          </div>
        </Link>
      );
    } else {
      return (
        <>
          <Disclosure key={uniqueKey}>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`${
                    !opened ? "opacity-50 pointer-events-none" : ""
                  }  w-full -mb-3 justify-between rounded-sm  p-2 text-left text-sm font-medium text-white hover:bg-red-500 hover:bg-opacity-30 focus:outline-none duration-300`}
                >
                  <div className="flex items-center justify-center">
                    <DocumentDuplicateIcon className={`w-5 h-5`} />
                    <p className={` ml-4 ${!opened && "hidden"}`}>
                      {formatRoleGroup(item.name)}
                    </p>
                    <ChevronLeftIcon
                      className={`${open ? "-rotate-90 transform" : ""} ${
                        !opened && "hidden"
                      } duration-300 h-5 w-5 text-white ml-auto`}
                    />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className={`-mb-4 `}>
                  {menu.map((item, i, arr) => {
                    return item.level === levels &&
                      item.parent_id === parentId ? (
                      <MenuDropdown
                        key={item.id}
                        item={item}
                        levels={levels + 1}
                        menu={arr}
                        parentId={item.id}
                      />
                    ) : null;
                  })}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <br />
        </>
      );
    }
  }

  useEffect(() => {
    getMenu();
    getServer();
  }, []);

  return (
    <section
      className={`bg-gradient-to-b from-red-900 via-red-800 to-red-700 h-screen  sticky z-30 top-0 pt-5 duration-300 ${
        opened
          ? "w-full sm:w-1/4 h-auto flex flex-col"
          : "hidden sm:w-1/12 sm:flex sm:flex-col"
      }`}
    >
      <ArrowLeftIcon
        className={`hidden sm:block bg-white text-red-800 p-2 rounded-full absolute w-10 h-10 -right-5 border-2 top-16
            border-yellow-400 cursor-pointer ${
              !opened && "rotate-180"
            } duration-700`}
        onClick={() => setOpened(!opened)}
      />
      {/* Tampilan Logo dan nama perusahaan jika terbuka */}
      <div
        className={`${
          !opened ? "hidden" : "flex"
        } w-full bg-white items-center p-2`}
      >
        <img
          src={CompanyLogo}
          alt="Logo Perusahaan"
          className={`rounded cursor-pointer block duration-300 ${
            opened ? "w-16 h-12" : "w-5 h-5"
          }`}
        />
        <h1
          className={`ml-3 text-slate-700 origin-left font-medium duration-300 ${
            !opened && "scale-0"
          }`}
        >
          Sharepoint
        </h1>
        <Bars3Icon
          className="text-slate-500 h-8 w-8 sm:hidden block ml-auto"
          onClick={() => setOpened(!opened)}
        />
      </div>

      {/* Tampilan Logo Perusahaan jika tertutup */}
      <div
        className={`${
          !opened ? "flex" : "hidden"
        } items-center pb-5 border-b-2 border-b-white border-opacity-30`}
      >
        <img src={CompanyLogoWhite} alt="Logo Putih" className="w-10 mx-auto" />
      </div>

      {menu.map((item, i, arr) => {
        const levels = 1;
        return item.level === levels ? (
          <MenuDropdown
            key={item.id}
            item={item}
            levels={levels + 1}
            menu={arr}
            parentId={item.id}
          />
        ) : null;
      })}
      {user.userInfo.rolegroup === "admin" && (
        <div className="grid grid-cols-2 bg-yellow-300 font-semibold text-white mt-auto">
          <div>
            <p>Server :</p>
          </div>

          {server ? (
            <button
              className="bg-green-500 text-white font-semibold hover:bg-green-600 transition duration-300"
              onClick={() => onServer(false)}
            >
              <p>Online</p>
            </button>
          ) : (
            <button
              className="bg-slate-200 text-slate-400 font-semibold hover:bg-slate-400 hover:text-white transition duration-300"
              onClick={() => onServer(true)}
            >
              <p>Offline</p>
            </button>
          )}
        </div>
      )}
      <div className={`grid grid-cols-2 ${user.userInfo.rolegroup != "admin" && "mt-auto"} font-semibold bg-white items-center justify-center`}>
        <div
          className={`${
            databases === "epicor"
              ? "bg-blue-950 text-white pointer-events-none"
              : "bg-white hover:bg-slate-200 cursor-pointer"
          }`}
          onClick={() => dispatch(database("epicor"))}
        >
          EPICOR
        </div>

        <div
          className={`${
            databases === "mas"
              ? "bg-blue-950 text-white pointer-events-none"
              : "bg-white hover:bg-slate-200 cursor-pointer"
          }`}
          onClick={() => dispatch(database("mas"))}
        >
          MAS
        </div>
      </div>

      <button
        className="hover:bg-yellow-400 duration-300 bg-yellow-300  h-10 flex items-center justify-center"
        onClick={() => onLogout()}
      >
        <p className="font-semibold text-white">Logout</p>
      </button>
    </section>
  );
};

export default SidebarNew;
