import React from "react";
import {
  Modal,
  Pagination,
  TableBody,
  ShowingItems,
  viewLink,
} from "../../component";
import { useSelector, useDispatch } from "react-redux";
import {
  PlusIcon,
  TrashIcon,
  PencilSquareIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { selectedList } from "../../reducers/listSlice";

function isInteger(str) {
  return /^-?\d*\.?\d+$/.test(str);
}

const LinkTable = ({
  header,
  header2,
  list,
  totalCount,
  query,
  page,
  setPage,
  bodyMapData,
  bodyMapData2,
  update,
  deleted,
  view,
  viewLink,
  editLink,
  deleteLink,
}) => {
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const handleStateUpdate = (item) => {
    dispatch(selectedList(item));
  };

  function TableHead({ head }) {
    return (
      <th scope="col" class="px-6 py-3">
        {head}
      </th>
    );
  }

  function TableHead2({ head }) {
    return (
      <th scope="col" class="px-6 py-3 bg-yellow-300">
        {head}
      </th>
    );
  }

  if (!Array.isArray(list)) {
    return <div>Loading...</div>;
  }
  return (
    <div className="register-button-div h-[600px] sm:h-[460px] ">
      <div class="table-div">
        <form>
          <table class="table-table">
            <thead class="table-thead sticky top-0">
              <tr>
                {header.map((item) => (
                  <TableHead head={item} />
                ))}
                {header2 && header2.map((item) => <TableHead2 head={item} />)}
                <th className="sticky right-0 text-center text-white bg-blue-500">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {list.map((item) => (
                <tr className="hover:bg-slate-200">
                  {item.id && (
                    <th scope="row" className="user-info border">
                      {item.id}
                    </th>
                  )}

                  {bodyMapData.map((mapItem) => (
                    <td  className={
                      isInteger(item[mapItem])
                        ? "text-right px-6 border py-2 text-base"
                        : "px-6 py-2 text-base border"
                    }>
                      {item[mapItem]}
                    </td>
                  ))}
                  {bodyMapData2 &&
                    bodyMapData2.map((mapItem) => (
                      <td  className={
                        isInteger(item[mapItem])
                          ? "text-right px-6 border py-2 text-base"
                          : "px-6 py-2 text-base border"
                      }>
                        {item[mapItem]}
                      </td>
                    ))}
                  <td className="px-6 py-4 border bg-white flex items-center justify-center">
                    {view === true && (
                      <Link
                        to={viewLink}
                        state={{ menu: item.id, menu_name: item.name }}
                      >
                        <button className="bg-blue-500 rounded-md hover:bg-blue-600 transition duration-300 text-white p-2 w-7 h-7 mr-2">
                          <EyeIcon />
                        </button>
                      </Link>
                    )}
                    {update === true && (
                      <Link
                        to={editLink}
                        state={{ menu: item.id, menu_name: item.name }}
                        onClick={() => handleStateUpdate(item)}
                        className={menu.update ? "" : "pointer-events-none"}
                      >
                        <button
                          onClick={() => {}}
                          className={
                            menu.update
                              ? "bg-yellow-400 rounded-md text-white p-2 w-7 h-7 mr-2"
                              : "bg-slate-500  bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
                          }
                        >
                          <PencilSquareIcon />
                        </button>
                      </Link>
                    )}
                    {deleted === true && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className={
                          menu.delete
                            ? "bg-red-500 rounded-md text-white p-2 w-7 h-7"
                            : "bg-slate-500 bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
                        }
                      >
                        <TrashIcon />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>
      </div>
      <Pagination
        itemsInPage={list.length}
        totalItems={totalCount}
        totalPages={Math.ceil(totalCount / parseInt(query.item))}
        currentPage={page}
        setCurrentPage={setPage}
      />
    </div>
  );
};

export default LinkTable;
