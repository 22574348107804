import React, { Fragment, useState, useEffect } from "react";
import { SidebarNew2, RegisterTable } from "../../component";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ShowingItems, Modal } from "../../component";
import { UserButtonCreateForm } from "../../component/form/RoleUserButtonSecurityForm";
import {
  RoleGroupMenuSecurityTable,
  RoleGroupButtonSecurityTable,
} from "../../component/table";
import { CreateForm } from "../../component/form/RoleUserMenuSecurityForm";
import { MenuDropDownFilter } from "../../component/filter";
import {
  Bars3Icon,
  ChevronLeftIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import jwt_decode from "jwt-decode";
import { clearListState } from "../../reducers/listSlice";
import axios from "axios";

const EditRoleUserMenuSecurity = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({});
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalButtonAdd, setShowModalButtonAdd] = useState(false);
  const [showModalButtonEdit, setShowModalButtonEdit] = useState(false);

  const [option, setOption] = useState(true);
  const [opened, setOpened] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({
    item: 5,
  });
  const [list, setList] = useState([]);
  const [buttonList, setButtonList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const listState = useSelector((state) => state.list);
  const header = ["id", "name", "create", "read", "update", "delete"];
  const headerButton = ["id", "menu", "menu button"];
  const bodyMapData = ["name"];

  const getToken = async () => {
    const token = localStorage.getItem("authorization");
    const decode = await jwt_decode(token, {
      header: process.env.REACT_APP_ACCESS_TOKEN,
    });
    setPayload(decode);
  };

  const getList = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/role_user_menu_security/get_specified/${page}/${listState.list.listId}`,
      {
        params: {
          ...query,
        },
      }
    );
    setList(result.data.result.rows);
    setTotalCount(result.data.result.count);
  };

  const getButtonList = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/role_user_button_security/get_with_pagination/${page}/${listState.list.listId}`,
      {
        params: {
          ...query,
        },
      }
    );
    setButtonList(result.data.result.rows);
    setTotalCount(result.data.result.count);
  };

  const handleBack = () => {
    dispatch(clearListState());
  };

  useEffect(() => {
    getToken();
    getList();
    getButtonList();
  }, []);

  useEffect(() => {
    getList();
  }, [page]);
  return (
    <Fragment>
      <div className="flex">
        <SidebarNew2 opened={opened} setOpened={setOpened} />
        <section
          className={`${
            opened ? "hidden sm:block sm:w-3/4" : "w-full sm:w-11/12"
          } duration-300 `}
        >
          <div className="flex flex-col pt-3 px-7 pb-3 bg-slate-200 items-center sm:items-start sticky top-0">
            <Bars3Icon
              onClick={() => setOpened(true)}
              className={`${
                !opened
                  ? "text-slate-500 w-7 h-7 absolute left-7 top-6 sm:hidden"
                  : "hidden"
              }`}
            />
            <h1 className="text-xl sm:px-0 px-4 sm:text-2xl font-semibold">Role User Menu Security </h1>
            <div className="flex items-center justify-center mt-1">
              <p className="text-xs text-slate-600 font-bold opacity-30 mr-2">
                Group :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30 pr-3 border-2 border-r-slate-600 border-opacity-30">
                {payload.rolegroup}
              </p>
              <p className="text-xs text-slate-600 font-bold opacity-30 mx-2">
                User :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30">
                {payload.name}{" "}
              </p>
            </div>
          </div>
          <div className="px-9 sm:px-14 py-5 h-4/5 relative">
            <section className="flex mb-3 border-b-2 pb-2">
              <button onClick={() => handleBack()}>
                <Link to={"/role_user_menu_security"}>
                  <ChevronLeftIcon className="w-6 h-6 py-1 hover:text-white duration-300 hover:bg-yellow-400 bg-yellow-300 rounded-sm mr-5" />
                </Link>
              </button>
              <h1 className="font-bold text-xl text-red-600">
                {listState.list.listName.toUpperCase()}
              </h1>
              <div className="ml-auto flex items-center justify-center">
                <MenuDropDownFilter
                  setMenuList={setList}
                  setTotalCount={setTotalCount}
                  page={page}
                  setSearchParams={setSearchParams}
                  setQuery={setQuery}
                  query={query}
                />
                {option ? (
                  <button
                    className="ml-3"
                    onClick={() => setShowModalAdd(!showModalAdd)}
                  >
                    <PlusIcon className="icon-style" />
                  </button>
                ) : (
                  <button
                    className="ml-3"
                    onClick={() => setShowModalButtonAdd(!showModalButtonAdd)}
                  >
                    <PlusIcon className="icon-style" />
                  </button>
                )}
              </div>
            </section>
            <section>
              <div className="flex items-start mb-2">
                <ShowingItems
                  setQuery={setQuery}
                  query={query}
                  getList={getList}
                />
                <h1 className="font-bold text-xs sm:text-xl ml-auto flex">
                  <button
                    onClick={() => setOption(!option)}
                    className={
                      option
                        ? "text-red-600 border-b-2 border-yellow-400 pointer-events-none duration-200"
                        : "disabled duration-200"
                    }
                  >
                    MENU
                  </button>
                  <p className="mx-3">/</p>
                  <button
                    onClick={() => setOption(!option)}
                    className={
                      !option
                        ? "text-red-600 border-b-2 border-yellow-400 pointer-events-none duration-200"
                        : "disabled duration-200"
                    }
                  >
                    BUTTON
                  </button>
                </h1>
              </div>
              {option ? (
                <RoleGroupMenuSecurityTable
                  header={header}
                  list={list}
                  query={query}
                  page={page}
                  totalCount={totalCount}
                  setPage={setPage}
                  setShowModalEdit={setShowModalEdit}
                  setSelectedId={setSelectedId}
                  getList={getList}
                  deleteLink={"/role_user_menu_security/delete"}
                />
              ) : (
                <RoleGroupButtonSecurityTable
                  header={headerButton}
                  list={buttonList}
                  query={query}
                  page={page}
                  setPage={setPage}
                  totalCount={totalCount}
                  getList={getButtonList}
                  setSelectedId={setSelectedId}
                  setShowModalEdit={setShowModalButtonEdit}
                  deleteLink={"/role_user_button_security/delete"}
                />
              )}
            </section>
          </div>
        </section>
        <Modal isVisible={showModalAdd} onClose={() => setShowModalAdd(false)}>
          <div className="px-6 py-4">
            <CreateForm
              onClose={() => setShowModalAdd(false)}
              getList={getList}
              type="create"
            />
          </div>
        </Modal>

        <Modal
          isVisible={showModalEdit}
          onClose={() => setShowModalEdit(false)}
        >
          <div className="px-6 py-4">
            <CreateForm
              onClose={() => setShowModalEdit(false)}
              getList={getList}
              type="update"
              id={selectedId}
            />
          </div>
        </Modal>

        {/* MODAL UNTUK BUTTON */}
        <Modal
          isVisible={showModalButtonAdd}
          onClose={() => setShowModalButtonAdd(false)}
        >
          <div className="px-6 py-4">
            <UserButtonCreateForm
            onClose={() => setShowModalButtonAdd(false)}
            getList={getButtonList}
            type="create"
          />
          </div>
        </Modal>
        <Modal
          isVisible={showModalButtonEdit}
          onClose={() => setShowModalButtonEdit(false)}
        >
          <div className="px-6 py-4">
            <UserButtonCreateForm
            onClose={() => setShowModalButtonEdit(false)}
            getList={getButtonList}
            type="update"
            id={selectedId}
          />
          </div>
        </Modal>
      </div>
    </Fragment>
  );
};

export default EditRoleUserMenuSecurity;
