import React, { useState, useEffect } from 'react'
import { useSelector} from 'react-redux'
import { useFormik } from 'formik'
import { successAlert, errorAlertWithMessage } from '../../../helper/alert'
import {DocumentIcon} from "@heroicons/react/24/outline"
import axios from 'axios'
import * as Yup from 'yup'

const CreateMenuForm = ({onClose, getList}) => {
    const user = useSelector((state) => state.user)
    const userId = user.userInfo.id
    const [parentName, setParentName] = useState([])
    const icon = [
        {
            name: "document",
            component: "DocumentIcon"
        },
        {
            name: "gear",
            component: "DocumentIcon"
        },
    ]

    const formik = useFormik({
        initialValues: {
            name: "",
            level: "",
            urut: "",
            parent_id:0,
            description:"",
            link:"",
            image: null
        },
        onSubmit: async(values) => {
            const userEdit = new FormData()
            userEdit.append("name", values.name)
            userEdit.append("level", values.level)
            userEdit.append("urut", values.urut)
            userEdit.append("parent_id", values.parent_id)
            userEdit.append("description", values.description)
            userEdit.append("link", values.link)
            userEdit.append("image", values.image)
            
            try {
                const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/menu/create/` + userId, userEdit)
                await successAlert(result.data.message)
                onClose()
                getList()
            } catch (error) {
                console.log("register error", error)
                await errorAlertWithMessage(error?.response?.data?.message)
            }      
            
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            level: Yup.string().required('Required'),
            urut: Yup.string().required('Required'),
            // level: Yup.string().email('Invalid email format').required("Required"),
        })
    })

    const handleLevelInput = async (e) => {
        const value = e.target.value;
        if (value <= "1") {
          formik.setFieldValue("parent_id", "");
          setParentName([])
        } else if (value > "1") {
            const parent = await axios.get(process.env.REACT_APP_BASE_URL + "/menu/parent/" + value)
            setParentName(parent.data.result)
        }
        console.log("ini parent", formik.values.parent_id)
      };
    
      const getUrut = async (e) => {
        const level = formik.values.level
        const parent = formik.values.parent_id
        if(level && parent) {
            // alert("gaskan")
            const urut = await axios.get(process.env.REACT_APP_BASE_URL + "/menu/urut/" + level + "/" + parent)
            console.log("ini urut", urut.data.result)
            formik.setFieldValue("urut", urut.data.result + 1 )
        }
        if(!parent) {
            formik.setFieldValue("urut", "")
        }
      }

      useEffect(() => {
        getUrut();
      }, [formik.values.parent_id]);

  return (
    <div className='flex flex-col'>
        <p className='text-left text-lg font-semibold border-b pb-2 mb-2'>Register New Menu</p>
        <form onSubmit={formik.handleSubmit}>
            <div className='grid gap-4 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-2'>
                <div className="sm:col-span-3">
                    <label htmlFor='name' className="input-label">Name </label>
                    <input type="text"className="input-field" id="name" name="name" {...formik.getFieldProps('name')}/>
                    {formik.touched.name && formik.errors.name ? (
                        <div className='text-red-500 text-left text-xs font-semibold'>{formik.errors.name}</div>
                    ):null}
                </div>
                <div>
                    <label htmlFor='level' className='input-label'>Level</label>
                    <input type="level" className='input-field' id="level" name="level" onChange={(e) => {formik.handleChange(e); handleLevelInput(e)}} value={formik.values.level} onBlur={formik.handleBlur}/>
                    {formik.touched.level && formik.errors.level ? (
                        <div className='text-red-500 text-left text-xs font-semibold'>{formik.errors.level}</div>
                    ) : null}
                </div>
                <div>
                    <label htmlFor='urut' className='input-label'>Urut</label>
                    <input type="urut" className="input-field" id="urut" name="urut" {...formik.getFieldProps('urut')}/>
                    {formik.touched.urut && formik.errors.urut ? (
                        <div className='text-red-500 text-left text-xs font-semibold'>{formik.errors.urut}</div>
                    ):null}
                </div>
                <div>
                <label htmlFor='parent_id' className='input-label'>parent</label>
                <select
                    className="input-field"
                    id="parent_id"
                    name="parent_id"
                    {...formik.getFieldProps('parent_id')}
                    value={formik.values.parent_id} // Make sure you set the selected value
                    onChange={(e) => {
                        const selectedValue = e.target.value;
                        formik.setFieldValue('parent_id', selectedValue); // Set the selected value
                    }}
                    >
                        <option value="0" disabled selected>Select parent</option>
                    {parentName.map((item) => (
                        <option key={item.id} value={item.id}>
                        {item.name}
                        </option>
                    ))}
                </select>
                </div>               
                <div className="sm:col-span-3">
                    <label htmlFor='description' className='input-label'>Description</label>
                    <input type="text" className='input-field' id="description" name="description" {...formik.getFieldProps('description')}/>
                </div>     
                <div className='sm:col-span-3'>
                    <label htmlFor='icon' className='input-label'>Icon</label>
                    <input type="file" className='block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400' id="image" name="image" onChange={(e) => {formik.setFieldValue('image', e.currentTarget.files[0])}}/>
                </div>    
            </div>
            <button type='submit' className='w-full bg-green-500 text-white rounded-md mt-6 py-2 font-bold text-md'>Submit</button>
        </form>
    </div>
  )
}

export default CreateMenuForm