import React, { useState, useEffect } from "react";
import { EditRow } from "./index";
import {
  PlusIcon,
  TrashIcon,
  PencilSquareIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import axios from "axios";

const TableBody = ({
  list,
  editFormData,
  handleEditFormChange,
  handleSave,
  handleCancel,
  showEditRow,
  bodyMapData,
  handleEdit,
  menu,
  viewLink,
  create,
  read,
  update,
  deleted,
  handleDelete,
}) => {
  const [allList, setAllList] = useState([]);
  const getAllList = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/menu/get_menu_all`
    );
    setAllList(result.data.result);
  };
  const findParent = (value) => {
    const parentItem = allList.find((listItem) => listItem.id === value);
    if (!parentItem) return "-";
    else return parentItem?.name;
  };
  function isInteger(str) {
    return /^\d+$/.test(str) && Number.isInteger(parseInt(str, 10));
  }

  useEffect(() => {
    getAllList();
  }, []);
  return list.map((item) =>
    item.id === showEditRow ? (
      <EditRow
        editFormData={editFormData}
        handleEditFormChange={handleEditFormChange}
        key={item.id}
        handleSave={handleSave}
        handleCancel={handleCancel}
        bodyMapData={bodyMapData}
      />
    ) : (
      <tr
        className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-slate-200 hover:bg-opacity-70"
        key={item.id}
      >
        <th scope="row" className="user-info">
          {item.id}
        </th>
        {bodyMapData.map((mapItem, index) => {
          return mapItem !== "parent_id" ? (
            <td
              className={
                isInteger(item[mapItem])
                  ? "text-right px-6 border py-2 text-base"
                  : "px-6 py-2 text-base border"
              }
            >
              {item[mapItem]}
            </td>
          ) : (
            <td className="px-6 py-2 text-base">
              {findParent(item.parent_id)}
            </td>
          );
        })}
        <td className="px-6 py-4 bg-white flex ">
          {read === true && (
            <Link to={viewLink} state={{ menu: item.id, menu_name: item.name }}>
              <button className="bg-blue-500 rounded-md text-white p-2 w-7 h-7 mr-2">
                <EyeIcon />
              </button>
            </Link>
          )}
          {update === true && (
            <button
              onClick={() => {
                handleEdit(item);
              }}
              className={
                menu.update
                  ? "bg-yellow-400 rounded-md text-white p-2 w-7 h-7 mr-2"
                  : "bg-slate-500 bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
              }
            >
              <PencilSquareIcon />
            </button>
          )}
          {deleted === true && (
            <button
              onClick={(e) => {
                e.preventDefault();
                handleDelete(item.id);
              }}
              className={
                menu.delete
                  ? "bg-red-500 rounded-md text-white p-2 w-7 h-7"
                  : "bg-slate-500 bg-opacity-70 rounded-md text-slate-200 p-2 w-7 h-7 mr-2 pointer-events-none"
              }
            >
              <TrashIcon />
            </button>
          )}
        </td>
      </tr>
    )
  );
};

export default TableBody;
