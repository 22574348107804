import { Routes, Route } from "react-router-dom";
import { LoginPage, HomePage, AddUserpage, Maintanacepage, TestingPage } from "./page";
import { DashboardReportingTrackingSO, DetailsReportingTrackingSO } from "./page/Reporting/ReportingTrackingSO"
import { DashboardMenu, ViewMenu } from "./page/RegisterMenu";
import { DashboardRoleGroup } from "./page/RoleGroup";
import { DashboardRoleUser } from "./page/RoleUser";
import {
  DashboardRoleUserMenuSecurity,
  EditRoleUserMenuSecurity,
} from "./page/RoleUserMenuSecurity";
import {
  DashboardRoleGroupMenuSercurity,
  EditRoleGroupMenuSecurity,
} from "./page/RoleGroupMenuSecurity";
import { DashboardMenuButton } from "./page/MenuButton";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <LoginPage />
            </>
          }
        />
        ,
        <Route
          path="/maintanance"
          element={
            <>
              <Maintanacepage />
            </>
          }
        />
        ,
        <Route
          path="/testing"
          element={
            <>
              <TestingPage />
            </>
          }
        />
        ,
        <Route
          path="/home"
          element={
            <>
              <HomePage />
            </>
          }
        />,

        <Route
          path="/register_user/add"
          element={
            <>
              <AddUserpage />
            </>
          }
        />
        {/* ------ MENU -------  */}
        <Route
          path="/register_menu"
          element={
            <>
              <DashboardMenu />
            </>
          }
        />
        {/* ------ ROLE GROUP ------- */}
        <Route
          path="/role_group"
          element={
            <>
              <DashboardRoleGroup />
            </>
          }
        />
        {/* ------ ROLE USER ------- */}
        <Route
          path="/role_user"
          element={
            <>
              <DashboardRoleUser />
            </>
          }
        />
        {/* ------ ROLE GROUP MENU SECURITY ------- */}
        <Route
          path="/role_group_menu_security"
          element={
            <>
              <DashboardRoleGroupMenuSercurity />
            </>
          }
        />
        <Route
          path="/edit_role_group_menu_security"
          element={
            <>
              <EditRoleGroupMenuSecurity />
            </>
          }
        />
        {/* ------ ROLE USER MENU SECURITY ------- */}
        <Route
          path="/role_user_menu_security"
          element={
            <>
              <DashboardRoleUserMenuSecurity />
            </>
          }
        />
        <Route
          path="/edit_role_user_menu_security"
          element={
            <>
              <EditRoleUserMenuSecurity />
            </>
          }
        />
        {/* ------ MENU BUTTONS ------- */}
        <Route
          path="/menu_buttons"
          element={
            <>
              <DashboardMenuButton />
            </>
          }
        />
        {/* ------ REPORTING TRACKING SO------- */}
        <Route
          path="/report_tracking_so"
          element={
            <>
              <DashboardReportingTrackingSO />
            </>
          }
        />
        <Route
          path="/report_tracking_so_details"
          element={
            <>
              <DetailsReportingTrackingSO />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
