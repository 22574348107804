import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SidebarNew2, RegisterTable } from '../component'
import jwt_decode from "jwt-decode"
import { useSelector, useDispatch } from 'react-redux'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { login } from '../reducers/userSlice'
import { Disclosure } from '@headlessui/react'
import { Pagination } from '../component'
import axios from 'axios'


const AddUserPage = () => {
    const [payload, setPayload] = useState({});
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState()
    const [opened, setOpened] = useState(false)
    const [userList, setUserList] = useState([])
    const header = [ "id", "name", "nik", "email", "email_verified_at", "password", "status"]

    const getUserList = async() => {
        const result = await axios.get(process.env.REACT_APP_BASE_URL + "/role_user/get_role_user/" + page)
        setUserList(result.data.result)
        setTotalCount(result.data.totalCount)
      }
    
      const getToken = async() => {
        const token = localStorage.getItem("authorization")
        const decode = await jwt_decode(token, {header: process.env.REACT_APP_ACCESS_TOKEN})
        setPayload(decode)
    
      }
    useEffect(() => {
        getUserList()
        getToken()
      },[page])

  return (
    <div className = 'flex'>
        {/* Wajib ada ----------------------------------------------------------*/}
        <SidebarNew2 opened={opened} setOpened={setOpened}/>
        <section className={`${opened ? "hidden sm:block sm:w-3/4" : "w-11/12"} duration-300 ` }>
            <div className='flex flex-col pt-3 px-7 pb-3 bg-slate-200 items-start sticky top-0'>
                <h1 className='text-2xl font-semibold'>Register User Page </h1>
                <div className='flex items-center mt-1'>
                <p className='text-xs text-slate-600 font-bold opacity-30 mr-2'>Group :</p>
                <p className='text-xs text-slate-600 font-semibold opacity-30 pr-3 border-2 border-r-slate-600 border-opacity-30'>{payload.rolegroup}</p>
                <p className='text-xs text-slate-600 font-bold opacity-30 mx-2'>User :</p>
                <p className='text-xs text-slate-600 font-semibold opacity-30'>{payload.name} </p>
                </div>               
            </div>
            {/* Fill content under here----------------------------------------- */}
            <div>
                <form>
                    <label>name</label>
                </form> 
            </div>
        </section>
        {/* ------------------------------------------------------------------- */}
    </div>
  )
}

export default AddUserPage