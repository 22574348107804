import React, { useState, CSSProperties } from "react";
import {ClimbingBoxLoader, BounceLoader} from "react-spinners";



const TestingPage = () => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");

  return (
    <div className="sweet-loading">
      <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
      <input
        value={color}
        onChange={(input) => setColor(input.target.value)}
        placeholder="Color of the loader"
      />

      
<BounceLoader color="#36d7b7" />
    </div>
  );
};

export default TestingPage;
