import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { SidebarNew2, ShowingItemsEpicor } from "../../../component";
import { useSearchParams } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { CabangFilter, DatePickerFilter } from "../../../component/filter";
import { ReportTrackingLinkTable } from "../../../component/table/report_tracking_so";
import jwt_decode from "jwt-decode";
import { Pagination } from "../../../component";
import MobileFilterComponent from "./MobileFilterComponent";
import { konversiFormatWaktu } from "../../../helper/DatabaseDateConverter";
import { BounceLoader } from "react-spinners";

const DashboardReportingTrackingSO = () => {
  const [payload, setPayload] = useState({});
  const [showGet, setShowGet] = useState(true);
  const [showGet2, setShowGet2] = useState(false);
  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sliceList, setSliceList] = useState([]);
  // UNTUK PILIH CABANG
  const [selectedCabang, setSelectedCabang] = useState(0);

  //UNTUK PAGINATION
  const [totalCount, setTotalCount] = useState(5);
  // UNTUK DATE PICKER
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [showing, setShowing] = useState(5);

  // UNTUK SLICING DARI LIST
  const [sliceStart, setSliceStart] = useState(0);
  const [sliceEnd, setSliceEnd] = useState(5);

  const header = ["DATE", "SO", "SHIPMENT", "%"];
  const header2 = ["CS-GUDANG", "RO-GUDANG", "PENGANTAR", "BALIK"];

  const getToken = async () => {
    const token = localStorage.getItem("authorization");
    const decode = await jwt_decode(token, {
      header: process.env.REACT_APP_ACCESS_TOKEN,
    });
    setPayload(decode);
  };

  const changeSlice = async () => {
    await setSliceStart(0);
    await setSliceEnd(parseInt(showing));
  };

  const getList = async () => {
    const config = {
      auth: {
        username: process.env.REACT_APP_USER_EPICOR,
        password: process.env.REACT_APP_PASSWORD_EPICOR,
      },
    };

    const startParam = searchParams.get("start");
    const endParam = searchParams.get("end");
    const cabangParam = searchParams.get("cabang");

    if (!startParam || !endParam || cabangParam === null) {
      alert("Pastikan Anda mengisi tanggal dan cabang dengan benar");
      return;
    }

    const convertStartDate = konversiFormatWaktu(startParam);
    const convertEndDate = konversiFormatWaktu(endParam);

    try {
      setLoading(true);
      setShowGet(true);
      const result = await axios.get(
        `https://ns2.adyaarthaabadi.com/AAAEpicorErp/api/v1/BaqSvc/AAA_R2_SOSerah_SUM/?Cabang=${cabangParam}&FromDate=${convertStartDate}&ToDate=${convertEndDate}`,
        config
      );
      setLoading(false);
      setShowGet(false);
      setList(result.data.value);
      setSliceList(result.data.value.slice(sliceStart, sliceEnd));
      setTotalCount(result.data.value.length);
      setShowGet(false);
      setShowGet2(true);
    } catch (error) {
      alert("Terjadi kesalahan saat mengambil data");
    }
  };

  const getSearchParams = () => {
    const checkStart = searchParams.get("start");
    if (checkStart) {
      getList();
    }
  };

  const paginationChange = () => {
    setSliceEnd(page * showing);
    setSliceStart(page * showing - showing);
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    getSearchParams();
  }, []);

  useEffect(() => {
    setSliceList(list.slice(sliceStart, sliceEnd));
  }, [sliceStart, sliceEnd]);

  useEffect(() => {
    changeSlice();
  }, [showing]);

  useEffect(() => {
    paginationChange();
  }, [page]);

  return (
    <Fragment>
      <div className="flex">
        <SidebarNew2 opened={opened} setOpened={setOpened} />
        <section
          className={`${
            opened ? "hidden sm:block sm:w-3/4" : "w-full sm:w-11/12"
          } duration-300 `}
        >
          <div className="flex flex-col pt-3 px-7 pb-3 bg-slate-200 items-center sm:items-start sticky top-0">
            <Bars3Icon
              onClick={() => setOpened(true)}
              className={`${
                !opened
                  ? "text-slate-500 w-7 h-7 absolute left-7 top-6 sm:hidden"
                  : "hidden"
              }`}
            />
            <h1 className="text-2xl font-semibold">Report Tracking SO</h1>
            <div className="flex items-center justify-center mt-1">
              <p className="text-xs text-slate-600 font-bold opacity-30 mr-2">
                Group :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30 pr-3 border-2 border-r-slate-600 border-opacity-30">
                {payload.rolegroup}
              </p>
              <p className="text-xs text-slate-600 font-bold opacity-30 mx-2">
                User :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30">
                {payload.name}{" "}
              </p>
            </div>
          </div>
          <div className="px-9 sm:px-14 py-5 h-4/5 relative">
            <div className="hidden sm:flex">
              <ShowingItemsEpicor setShowing={setShowing} />
              <DatePickerFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                selectedCabang={selectedCabang}
              />
              <CabangFilter
                startDate={startDate}
                endDate={endDate}
                setSelectedCabang={setSelectedCabang}
                selectedCabang={selectedCabang}
              />
              {showGet2 && (
                <div>
                  <button
                    className="blue-button w-full ml-3"
                    onClick={() => {
                      getList();
                    }}
                  >
                    Get Data
                  </button>
                </div>
              )}
            </div>
            {loading && (
              <div className="flex items-center justify-center mt-10">
                <BounceLoader color="#4A84FF" />
              </div>
            )}
            <div className="sm:hidden flex  justify-end">
              <MobileFilterComponent
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                selectedCabang={selectedCabang}
                setSelectedCabang={setSelectedCabang}
              />
            </div>
            {showGet ? (
              <div>
                <button
                  className="blue-button w-full mt-3"
                  onClick={() => {
                    getList();
                  }}
                >
                  Get Data
                </button>
              </div>
            ) : (
              <>
                <ReportTrackingLinkTable
                  header={header}
                  header2={header2}
                  list={sliceList}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  view={true}
                  viewLink={"/report_tracking_so_details"}
                />
                <Pagination
                  itemsInPage={sliceList.length}
                  totalItems={totalCount}
                  totalPages={Math.ceil(totalCount / showing)}
                  currentPage={page}
                  setCurrentPage={setPage}
                  itemsPerPage={showing}
                />
              </>
            )}
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default DashboardReportingTrackingSO;
