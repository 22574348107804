import Swal from "sweetalert2"

function successAlert(message = "") {
    Swal.fire({
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500
    })
}

function errorAlertWithMessage(message = "") {
    Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
    })
}

async function deleteConfirmationAlert(cb = () => {}) {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#06b6d4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });
    if (result.isConfirmed) {
      cb();
    }
  }

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure you want to delete this menu ?",
      icon: "warning",
      showCancleButton: true,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted !",'', "success")
      } else if (result.isDenied){
        Swal.fire('Changes are not saved','', 'info')
      }
    })
  }

export {
    successAlert,
    errorAlertWithMessage,
    deleteConfirmationAlert,
    handleDelete
}