import React from 'react'

const LoginNavbar = () => {
  return (
    <div className='bg-red-600 '>
        <div className='container-screen flex py-8'>
            <p className='mr-auto font-semibold text-white'>PT Adya Artha Abadi</p>
        </div>      
    </div>
  )
}

export default LoginNavbar