import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token:"",
    userInfo:{
        name: "",
        rolegroup:"",
        rolegroup_id:"",
        name:""
    }
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => {
            state.token = action.payload.access     
            state.userInfo = {
                nik: action.payload.result.nik,
                id: action.payload.result.id,
                rolegroup: action.payload.result.role_group.name,
                rolegroup_id: action.payload.result.rolegroup_id,
                name: action.payload.result.name,
            }     
        }
    }
})

export const { login } = userSlice.actions;
export default userSlice.reducer;