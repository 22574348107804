import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { successAlert, errorAlertWithMessage } from "../../../helper/alert";
import axios from "axios";
import * as Yup from "yup";

const MenuButtonForm = ({ onClose, getList, type, id }) => {
  const user = useSelector((state) => state.user);
  const [menuList, setMenuList] = useState([]);

  const getMenuRoleSecurityList = async () => {
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/menu_buttons/get_one/" + id
    );

    formik.setValues({
      menu_id: result.data.result.menu_id,
      name: result.data.result.name,
      caption: result.data.result.caption,
      description: result.data.result.description,
    });
  };

  const getMenuList = async () => {
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/menu/get_menu_all"
    );
    setMenuList(result.data.result);
  };

  useEffect(() => {
    getMenuList();

    if (type === "update") {
      getMenuRoleSecurityList();
    }
  }, []);

  // ========================================================================

  const userId = user.userInfo.id;
  const formik = useFormik({
    initialValues: {
      name:"",
      caption:"",
      description:"",
      menu_id:"",
    },
    onSubmit: async (values) => {
      const data = {};
      data.menu_id = values.menu_id;
      data.name = values.name
      data.caption = values.caption
      data.description = values.description

      try {
        if (type === "create") {
          const result = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/menu_buttons/register/` +
              userId,
            data
          );
          await successAlert(result.data.message);
          onClose();
          getList();
        } else if (type === "update") {
          const result = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/menu_buttons/update/${id}/${user.userInfo.id}`,
            data
          );
          await successAlert(result.data.message);
          onClose();
          getList();
        }
      } catch (error) {
        await errorAlertWithMessage(error?.response?.data?.message);
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      menu_id: Yup.string().required("Required")
    }),
  });
  return (
    <div className="flex flex-col">
      <p className="text-left text-lg font-semibold border-b pb-2 mb-2">
        Menu Button
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid gap-4 sm:grid-cols-4 sm:gap-x-6 sm:gap-y-2">
          <div className="sm:col-span-2">
            <label htmlFor="name" className="input-label">
              Name
              {type}
            </label>
            <input
              type="text"
              className="input-field"
              id="name"
              name="name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-left text-xs font-semibold">
                {formik.errors.name}
              </div>
            ) : null}
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="menu_id" className="input-label">
              Menu
            </label>
            <select
              className="input-field"
              id="menu_id"
              name="menu_id"
              {...formik.getFieldProps("menu_id")}
              value={formik.values.menu_id} // Make sure you set the selected value
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.setFieldValue("menu_id", selectedValue); // Set the selected value
              }}
            >
              <option value="" disabled selected>
                Select menu
              </option>
              {menuList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="sm:col-span-4">
            <label htmlFor="caption" className="input-label">
              Caption
            </label>
            <input
              type="text"
              className="input-field"
              id="caption"
              name="caption"
              {...formik.getFieldProps("caption")}
            />
          </div>
          <div className="sm:col-span-4">
            <label htmlFor="description" className="input-label">
              Description
            </label>
            <textarea
              className="input-field"
              id="description"
              name="description"
              {...formik.getFieldProps("description")}
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 text-white rounded-md mt-6 py-2 font-bold text-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default MenuButtonForm;
