function konversiTanggal(tanggalInput) {
    // Buat objek tanggal dari string input
    var tanggal = new Date(tanggalInput);
  
    // Dapatkan komponen tanggal, bulan, dan tahun
    var tanggalAngka = tanggal.getDate();
    var bulanAngka = tanggal.getMonth() + 1; // Ingat, bulan dimulai dari 0
    var tahunAngka = tanggal.getFullYear();
  
    // Tambahkan "0" di depan jika tanggal atau bulan kurang dari 10
    var tanggalString = tanggalAngka < 10 ? '0' + tanggalAngka : tanggalAngka;
    var bulanString = bulanAngka < 10 ? '0' + bulanAngka : bulanAngka;
  
    // Gabungkan komponen-komponen untuk mendapatkan format yang diinginkan
    var hasilFormat = tanggalString + '-' + bulanString + '-' + tahunAngka;
  
    return hasilFormat;
  }
  function konversiFormatWaktu(inputTime) {
    const time = new Date(inputTime);
  
    // Get year, month, and day
    const year = time.getFullYear();
    // Add 1 to the month because months are zero-indexed
    const month = (time.getMonth() + 1).toString().padStart(2, '0');
    const day = time.getDate().toString().padStart(2, '0');
  
    // Create the database format with the constant time part
    const databaseFormat = `${year}-${month}-${day}T00%3A00%3A00`;
  
    return databaseFormat;
  }

  export { konversiTanggal, konversiFormatWaktu }