import React, { Fragment, useState, useEffect } from "react";
import { SidebarNew2, RegisterTable } from "../../component";
import { MenuDropDownFilter } from "../../component/filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LinkTable } from "../../component/table";
import { ShowingItems, Modal } from "../../component";
import { Bars3Icon } from "@heroicons/react/24/outline";
import jwt_decode from "jwt-decode";
import axios from "axios";

const DashboardRoleUserMenuSecurity = () => {
  const [payload, setPayload] = useState({});
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({
    item: 5,
  });
  const [list, setList] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const header = ["id", "name"];
  const bodyMapData = ["name"];

  const getToken = async () => {
    const token = localStorage.getItem("authorization");
    const decode = await jwt_decode(token, {
      header: process.env.REACT_APP_ACCESS_TOKEN,
    });
    setPayload(decode);
  };

  const getList = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/role_user/get_role_user/${page}`,
      {
        params: {
          ...query,
        },
      }
    );
    setList(result.data.result.rows);
    setTotalCount(result.data.result.count);
  };

  useEffect(() => {
    getToken();
    // BUAT LIST NYA DULU BARU DI UNCOMMENT
    getList();
  }, []);

  // BUAT LISTNYA DULU BARU DI UNCOMMENT
  useEffect(() => {
    getList();
  }, [page]);
  return (
    <Fragment>
      <div className="flex">
        <SidebarNew2 opened={opened} setOpened={setOpened} />
        <section
          className={`${
            opened ? "hidden sm:block sm:w-3/4" : "w-full sm:w-11/12"
          } duration-300 `}
        >
          <div className="flex flex-col pt-3 px-7 pb-3 bg-slate-200 items-center sm:items-start sticky top-0 z-10">
            <Bars3Icon
              onClick={() => setOpened(true)}
              className={`${
                !opened
                  ? "text-slate-500 w-7 h-7 absolute left-7 top-6 sm:hidden"
                  : "hidden"
              }`}
            />
            <h1 className="text-xl px-4 sm:px-0 font-semibold sm:text-2xl">Role User Menu Security </h1>
            <div className="flex items-center justify-center mt-1">
              <p className="text-xs text-slate-600 font-bold opacity-30 mr-2">
                Group :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30 pr-3 border-2 border-r-slate-600 border-opacity-30">
                {payload.rolegroup}
              </p>
              <p className="text-xs text-slate-600 font-bold opacity-30 mx-2">
                User :
              </p>
              <p className="text-xs text-slate-600 font-semibold opacity-30">
                {payload.name}{" "}
              </p>
            </div>
          </div>
          <div className="px-9 sm:px-14 py-5 h-4/5 relative">
            <div className="flex mb-3">
              <ShowingItems
                setSearchParams={setSearchParams}
                setQuery={setQuery}
                query={query}
                getList={getList}
              />
              <MenuDropDownFilter
                setMenuList={setList}
                setTotalCount={setTotalCount}
                page={page}
                setSearchParams={setSearchParams}
                setQuery={setQuery}
                query={query}
              />
            </div>
            <LinkTable
              header={header}
              list={list}
              totalCount={totalCount}
              query={query}
              page={page}
              setPage={setPage}
              bodyMapData={bodyMapData}
              update={true}
              editLink={"/edit_role_user_menu_security"}
            />
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default DashboardRoleUserMenuSecurity;
