import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    database:"epicor"
}

export const databaseSlice = createSlice({
    name: "database",
    initialState,
    reducers: {
        database: (state, action) => {
            state.database = action.payload    
        }
    }
})

export const { database } = databaseSlice.actions;
export default databaseSlice.reducer;