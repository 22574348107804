import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    create: false,
    read: false,
    update: false,
    delete: false
}

export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        menuAccess: (state, action) => {    
            state.create = action.payload.create
            state.read = action.payload.read
            state.update = action.payload.update
            state.delete = action.payload.delete    
        }
    }
})

export const { menuAccess } = menuSlice.actions;
export default menuSlice.reducer;