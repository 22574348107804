import React, { useState, useEffect } from 'react'
import { useSelector} from 'react-redux'
import { useFormik } from 'formik'
import { successAlert, errorAlertWithMessage } from '../../../helper/alert'

import axios from 'axios'
import * as Yup from 'yup'

const CreateRoleGroupForm = ({onClose, getList}) => {
    const user = useSelector((state) => state.user)
    const userId = user.userInfo.id
    const formik = useFormik({
        initialValues: {
            name: "",
        },
        onSubmit: async(values) => {
            const userEdit = new FormData()
            userEdit.append("name", values.name)   
            const data = {}
            data.name = formik.values.name   
            console.log("ini user edit", data) 
            try {
                const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/role_group/register_role/` + userId, data)
                await successAlert(result.data.message)
                onClose()
                getList()
            } catch (error) {
                console.log("register error", error)
                await errorAlertWithMessage(error?.response?.data?.message)
            }      
            
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            // level: Yup.string().email('Invalid email format').required("Required"),
        })
    })
  return (
    <div className='flex flex-col'>
        <p className='text-left text-lg font-semibold border-b pb-2 mb-2'>Register New Role Group</p>
        <form onSubmit={formik.handleSubmit}>
            <div className='grid gap-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-2'>
                <div className="sm:col-span-2">
                    <label htmlFor='name' className="input-label">Name</label>
                    <input type="text"className="input-field" id="name" name="name" {...formik.getFieldProps('name')}/>
                    {formik.touched.name && formik.errors.name ? (
                        <div className='text-red-500 text-left text-xs font-semibold'>{formik.errors.name}</div>
                    ):null}
                </div>                
            </div>
            <button type='submit'  className='w-full bg-green-500 text-white rounded-md mt-6 py-2 font-bold text-md'>Submit</button>
        </form>
    </div>
  )
}

export default CreateRoleGroupForm